<template>
    <div id="new_welcome">
        <nav class="nav home-nav justify-content-center sticky-top d-none d-xl-flex"
            v-scroll-spy="{ sectionSelector: '.scroll-section', navLinkSelector: '.nav-item .nav-link', offset: 300, activeClass: 'active' }">

            <li class="nav-item" v-for="section in sections" :key="section.id">
                <router-link tag="a" class="nav-link" :to="{ hash: section.id }" :key="section.id" exact
                    active-class="active">
                    <span v-text="section.title"></span>
                </router-link>
            </li>

        </nav>
        <section id="study" class="section-1 scroll-section">
            <row>
                <column :sizes="{ md: 12, lg: 4 }">
                    <div class="d-flex flex-column h-100 text-center text-lg-start">
                        <img id="mainHPOSLogo" :src="'/images/hpos_logo.svg'" :alt="$t('public.home.hpos_logo_alt')"
                            class="img-fluid" />
                        <p class="text-center">{{ $t('public.new_welcome.full_title') }}</p>
                        <h1 class="hero-heading uppercase">{{ $t('public.new_welcome.hero_title_1') }}</h1>
                        <h1 class="hero-heading highlight uppercase">{{ $t('public.new_welcome.hero_title_2') }}</h1>
                        <br>
                        <h2>{{ $t('public.new_welcome.hero_subtitle') }}</h2>
                        <br>
                        <p class="lead mb-4">{{ $t('public.new_welcome.hero_title_3') }}</p>
                        <btn is-router-link :to="{ name: 'register' }"
                            class="mt-auto d-flex justify-content-between align-items-center rounded-pill">
                            <div class="fs-1 ms-3" v-t="'login.register'"></div>
                            <fa icon="circle-arrow-right" size="3x" />
                        </btn>
                    </div>
                </column>
                <column :sizes="{ md: 12, lg: 8 }">
                    <div class="stack patients-hero" v-scroll-trigger="{ threshold: 0 }">
                        <svg class="stack-bg" viewBox="0 0 878.42 920.44">
                            <defs>
                                <linearGradient id="myGradient" gradientTransform="rotate(0)">
                                    <stop offset="5%" stop-color="var(--secondary-color)" />
                                    <stop offset="95%" stop-color="var(--bg-blob-color)"></stop>
                                </linearGradient>
                            </defs>
                            <g>
                                <ellipse cx="820" cy="510" rx="600" ry="408" fill="url(#myGradient)"></ellipse>
                            </g>
                        </svg>
                        <svg class="stack-0" viewBox="0 0 878.42 920.44">
                            <use class="molecule" :href="'/images/home/hero-gfx.svg#molecule1'"></use>
                            <use class="molecule" :href="'/images/home/hero-gfx.svg#molecule2'"></use>
                            <use class="molecule" :href="'/images/home/hero-gfx.svg#molecule3'"></use>
                            <use class="molecule" :href="'/images/home/hero-gfx.svg#molecule4'"></use>
                            <use class="molecule" :href="'/images/home/hero-gfx.svg#molecule5'"></use>

                        </svg>

                        <svg class="stack-1" viewBox="0 0 878.42 920.44">
                            <use :href="'/images/home/hero-gfx.svg#girl'"></use>
                        </svg>
                        <svg class="stack-2 transitional" viewBox="0 0 878.42 920.44">
                            <use :href="'/images/home/hero-gfx.svg#boy'"></use>
                        </svg>

                        <!-- <img class="stack-0" :src="'/images/home/hero-gfx/bg_shapes.svg'" />
                        <img class="stack-1" :src="'/images/home/hero-gfx/girl.svg'" />
                        <img class="stack-2 transitional" :src="'/images/home/hero-gfx/boy.svg'" /> -->
                    </div>
                </column>
            </row>
            <row class="my-5">
                <column :sizes="{ md: 12, lg: 6 }">
                    <aside v-scroll-trigger="{ threshold: 0.1 }">
                        <card class="hpos-card rounded-4" :body="false">
                            <div class="p-2 px-md-5 text-center ">
                                <h1 class="hero-heading mb-4">1/3</h1>
                                <p class="lead" v-t="'public.new_welcome.about_the_study.aside'"></p>
                            </div>
                        </card>
                    </aside>
                </column>
                <column :sizes="{ md: 12, lg: 6 }" class="pe-4 text-md-center text-lg-end m-2 m-lg-0">
                    <h1 class="hero-heading">{{ $t('public.new_welcome.about_the_study.title')
                        }}</h1>
                    <p class="lead" v-t="'public.new_welcome.about_the_study.p1'"></p>
                    <i18n tag="p" path="public.new_welcome.about_the_study.link1.text" class="text-lg-end">
                        <template #link>
                            <a :href="$t('public.new_welcome.about_the_study.link1.url')" target="_blank">{{
                                $t('public.new_welcome.about_the_study.link1.link_text') }}</a>
                        </template>
                    </i18n>
                    <p class="text-lg-end" v-t="'public.home.story_2.para_1'"></p>

                </column>
            </row>
            <div class="breakout">

                <div class="banner container-xxl">
                    <row class="d-flex align-items-center">
                        <column :sizes="{ md: 4 }">
                            <h1 class="hero-heading">{{ $t('public.new_welcome.aims_of_the_study.title_pre') }}</h1>
                            <h1 class="hero-heading uppercase">{{ $t('public.new_welcome.aims_of_the_study.title') }}
                            </h1>
                        </column>
                        <column :sizes="{ md: 8 }">
                            <div class="d-flex">
                                <div class="vr d-none d-md-block me-md-2"></div>
                                <div class="my-4 mx-lg-5">
                                    <p class="lead" v-t="'public.new_welcome.aims_of_the_study.p1'"></p>
                                    <p class="lead" v-t="'public.new_welcome.aims_of_the_study.p2'"></p>
                                </div>
                            </div>
                        </column>
                    </row>

                </div>
                <div class="banner-bg"></div>
            </div>
            <div class="mt-5 fade-in" v-scroll-trigger="{ threshold: 0 }">
                <p class="lead text-center my-5">{{ $t('public.new_welcome.what_is_involved.p1') }}</p>

                <row class="text-center ">
                    <column :sizes="{ md: 6, xl: 2 }" :offset="{ xl: 1 }">
                        <div class="p-2">
                            <svg class="svg-inline--fa fa-5x fa-fw mb-4">
                                <use :href="'/images/home/icon-sprite.svg#calendar'"></use>
                            </svg>
                            <p class="lead text-center" v-t="'public.new_welcome.what_is_involved.list-items.1.text'">
                            </p>
                        </div>
                    </column>
                    <column :sizes="{ md: 6, xl: 2 }">
                        <div class="p-2">
                            <svg class="svg-inline--fa fa-5x fa-fw mb-4">
                                <use :href="'/images/home/icon-sprite.svg#hand-choice'"></use>
                            </svg>
                            <p class="lead text-center" v-t="'public.new_welcome.what_is_involved.list-items.2.text'">
                            </p>
                        </div>
                    </column>
                    <column :sizes="{ md: 6, xl: 2 }">
                        <div class="p-2">
                            <svg class="svg-inline--fa fa-5x fa-fw mb-4">
                                <use :href="'/images/home/icon-sprite.svg#questionnaire-laptop'"></use>
                            </svg>
                            <p class="lead text-center" v-t="'public.new_welcome.what_is_involved.list-items.3.text'">
                            </p>
                        </div>
                    </column>
                    <column :sizes="{ md: 6, xl: 2 }">
                        <div class="p-2">
                            <svg class="svg-inline--fa fa-5x fa-fw mb-4">
                                <use :href="'/images/home/icon-sprite.svg#user-choice'"></use>
                            </svg>
                            <p class="lead text-center" v-t="'public.new_welcome.what_is_involved.list-items.4.text'">
                            </p>
                        </div>
                    </column>
                    <column :sizes="{ md: 6, xl: 2 }" :offset="{ md: 3, lg: 0 }">
                        <div class="p-2">
                            <svg class="svg-inline--fa fa-5x fa-fw mb-4">
                                <use :href="'/images/home/icon-sprite.svg#security-badge'"></use>
                            </svg>
                            <p class="lead text-center" v-t="'public.new_welcome.what_is_involved.list-items.5.text'">
                            </p>
                        </div>
                    </column>
                </row>
            </div>
            <div class="breakout">
                <div class="banner container-xxl">
                    <row class="d-flex align-items-center">
                        <column :sizes="{ md: 4 }">
                            <h1 class="hero-heading uppercase">{{ $t('public.new_welcome.how_far_have_we_come.title') }}
                            </h1>
                        </column>
                        <column :sizes="{ md: 8 }">
                            <div class="d-flex">
                                <div class="vr d-none d-md-block me-md-2"></div>
                                <div class="my-4 mx-lg-5 z-1">
                                    <i18n path="public.new_welcome.how_far_have_we_come.big-stat" tag="p" class="lead">
                                        <template #number>
                                            <div class="massive-text">
                                                {{ patientCount }}
                                            </div>
                                        </template>
                                    </i18n>
                                </div>
                                <!-- <force-diagram class="force-diagram img-fluid" :width="800" :height="200"
                                    :alt="$t('public.home.story_2.image_alt')" v-bind="{
                                        type: 'canvas',
                                        width: 800,
                                        height: 200,
                                        nodes,
                                        radius: 2,
                                        padding: 2,
                                        chargeStrength: 0.01,
                                        clusterStrength: 0.1,
                                        collideStrength: 0.4,
                                        // centerActive: true,
                                        chargeActive: true,
                                        // clusterActive: true,
                                        collideActive: true,
                                        nodeFill: '#2a655f',
                                        startPositions: { x: 800, y: 100 }
                                    }">

                                </force-diagram> -->
                            </div>
                        </column>
                    </row>

                </div>
                <div class="banner-bg"></div>

            </div>
        </section>
        <section id="eligibility" class="section-2 scroll-section overflow-hidden">
            <!-- Eligibility section -->
            <row>
                <!-- Who are we interested in -->
                <column :sizes="{ lg: 12, xl: 5 }">
                    <div class="mx-2">
                        <h1 class="hero-heading uppercase">{{ $t('public.new_welcome.who_are_we_interested_in.title_1')
                            }}
                        </h1>
                        <h1 class="hero-heading uppercase highlight mb-lh">{{
                            $t('public.new_welcome.who_are_we_interested_in.title_2') }}
                        </h1>
                        <p class="lead mb-lh" v-t="'public.new_welcome.who_are_we_interested_in.p1'"></p>
                        <p class="lead" v-t="'public.new_welcome.who_are_we_interested_in.p2'"></p>
                        <ul class="ps-2" :aria-label="$t('public.new_welcome.who_are_we_interested_in.p2')">
                            <li class="d-flex align-items-center mb-4 fade-in" v-scroll-trigger="{ threshold: 1 }">
                                <svg style="color: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                    <use :href="'/images/home/icon-sprite.svg#check-circle'"></use>
                                </svg>
                                <p class="fw-bold m-0"
                                    v-t="'public.new_welcome.who_are_we_interested_in.eligibility_list.1'">
                                </p>
                            </li>
                            <li class="d-flex align-items-center  mb-2 fade-in" v-scroll-trigger="{ threshold: 1 }">
                                <svg style="color: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                    <use :href="'/images/home/icon-sprite.svg#check-circle'"></use>
                                </svg>
                                <p class="fw-bold m-0"
                                    v-t="'public.new_welcome.who_are_we_interested_in.eligibility_list.2'">
                                </p>
                            </li>
                        </ul>
                        <p class="lead mt-4" v-t="'public.new_welcome.who_are_we_interested_in.p3'"></p>
                        <ul class="ps-2" :aria-label="$t('public.new_welcome.who_are_we_interested_in.p3')">
                            <li class="d-flex align-items-top mb-4 fade-in" v-scroll-trigger="{ threshold: 1 }">
                                <svg style="color: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                    <use :href="'/images/home/icon-sprite.svg#check-circle'"></use>
                                </svg>
                                <p class="fw-bold m-0"
                                    v-t="'public.new_welcome.who_are_we_interested_in.benefit_list.1'">
                                </p>
                            </li>
                            <li class="d-flex align-items-top  mb-2 fade-in" v-scroll-trigger="{ threshold: 1 }">
                                <svg style="color: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                    <use :href="'/images/home/icon-sprite.svg#check-circle'"></use>
                                </svg>
                                <p class="fw-bold m-0"
                                    v-t="'public.new_welcome.who_are_we_interested_in.benefit_list.2'">
                                </p>
                            </li>
                        </ul>
                    </div>

                </column>
                <column :sizes="{ xl: 2, lg: 6 }" class="slide-in-bottom" v-scroll-trigger="{ threshold: 0.5 }">
                    <badge type="custom" style="background: var(--primary-highlight-color);" class=" rounded me-2 mb-2">
                        <span v-t="'public.new_welcome.who_are_we_interested_in.available'"></span>
                    </badge>
                    <div class="flag-grid mb-4">
                        <div v-for="(country, n) in availableInCountries" :key="country.countryCode">
                            <svg class="flag-img card-img rounded" :alt="country.name"
                                :id="'flag_' + $t('public.home.countries.ca_list.' + n, 'en').name"
                                :key="'flag_' + $t('public.home.countries.ca_list.' + n, 'en').name"
                                @mouseover="(e) => hoverOnFlag(e, true)" @mouseleave="(e) => hoverOnFlag(e, false)">
                                <use :href="'/images/home/flags-sprite.svg#' + country.countryCode.toLowerCase()">
                                </use>
                            </svg>

                        </div>
                    </div>
                    <badge type="custom" style="background: var(--secondary-color);" class=" rounded me-2 mb-2">
                        <span v-t="'public.new_welcome.who_are_we_interested_in.coming_soon'"></span>
                    </badge>
                    <div class="flag-grid">
                        <div v-for="(country, n) in comingSoonCountries" :key="country.countryCode">
                            <svg class="flag-img card-img rounded" :alt="country.name"
                                :id="'flag_' + $t('public.home.countries.ls_list.' + n, 'en').name"
                                :key="'flag_' + $t('public.home.countries.ls_list.' + n, 'en').name"
                                @mouseover="(e) => hoverOnFlag(e, true)" @mouseleave="(e) => hoverOnFlag(e, false)">
                                <use :href="'/images/home/flags-sprite.svg#' + country.countryCode.toLowerCase()">
                                </use>
                            </svg>

                        </div>
                    </div>
                </column>
                <column :sizes="{ xl: 5, lg: 6 }" class="slide-in-right" v-scroll-trigger="{ threshold: 0.05 }">
                    <svg viewBox="0 0 1200 1640" class="europe-map img-fluid">
                        <GeoMap geoDataUrl="assets/json/europe_features.json" :width="1200" :height="1640"
                            :center="[12, 57]" :scale="1250" :colours="countryColours" :countryClasses="countryClasses"
                            colour-key="name">
                            <template #overlay="{ x, y, feature }">
                                <use v-if="isFeaturedCountry(feature.properties.name)" :x="x - 16" :y="y - 12"
                                    width="42" height="32"
                                    :href="'/images/home/flags-sprite.svg#' + featuredCountry(feature.properties.name).countryCode.toLowerCase()">
                                </use>
                            </template>
                        </GeoMap>
                    </svg>
                </column>
            </row>

        </section>
        <section id="who_are_we" class="section-3 scroll-section">
            <row class="mb-md-4">
                <column :sizes="{ md: 12, lg: 6 }" class="d-flex flex-column">
                    <div class="mx-2 d-flex flex-column h-100">
                        <h1 class="hero-heading uppercase mb-lh">{{ $t('public.new_welcome.who_are_we.title') }}</h1>
                        <p class="lead mb-lh" v-t="'public.new_welcome.who_are_we.p1'"></p>
                        <p class="lead mb-lh" v-t="'public.new_welcome.who_are_we.p2'"></p>
                        <i18n tag="p" class="lead mb-4" path="public.new_welcome.who_are_we.p3">
                            <template #link>
                                <a :href="$t('public.new_welcome.who_are_we.link.url')" target="_blank">{{
                                    $t('public.new_welcome.who_are_we.link.text') }}</a>
                            </template>
                        </i18n>
                        <div class="d-flex flex-wrap mt-auto partners-list shadow-sm rounded-4">
                            <a class="m-1" :href="$t('public.home.partners.list.0.url')">
                                <img :src="$t('public.home.partners.list.0.img')"
                                    :title="$t('public.home.partners.list.0.title')" class="img-fluid" />
                            </a>
                            <a class="m-1" :href="$t('public.home.partners.list.1.url')">
                                <img :src="$t('public.home.partners.list.1.img')"
                                    :title="$t('public.home.partners.list.1.title')" class="img-fluid" />
                            </a>
                            <a class="m-1" :href="$t('public.home.partners.list.2.url')">
                                <img :src="$t('public.home.partners.list.2.img')"
                                    :title="$t('public.home.partners.list.2.title')" class="img-fluid" />
                            </a>

                        </div>
                    </div>
                </column>
                <column :sizes="{ md: 12, lg: 6 }">
                    <youtube :video-id="videoId" ref="youtube" class="laura-video-youtube rounded-4 shadow-sm">
                    </youtube>
                </column>
            </row>
            <row class="mt-4">
                <column :sizes="{ md: 12, lg: 4 }" v-for="staff in $t('public.more-info.who_are_we.staff')"
                    class="d-flex  justify-content-center" :key="'div_' + staff.title">
                    <figure class="figure" v-scroll-trigger="{ threshold: 0 }">
                        <img :src="staff.photo" :alt="staff.title" class="figure-img img-fluid rounded-4 shadow-sm">
                        <figcaption class="figure-caption text-center"><a :href="staff.link" v-text="staff.title"></a>
                        </figcaption>
                    </figure>
                </column>
            </row>
        </section>
        <section id="what_is_expected" class="section-4 scroll-section">
            <row>
                <!-- What is expected -->

                <column :sizes="{ lg: 12, xl: 6 }" :offset="{ xl: 1 }" class="text-lg-end order-0 order-xl-1">
                    <row class=" mb-lh">
                        <column :sizes="{ lg: 6 }" :offset="{ lg: 6 }">
                            <aside v-scroll-trigger="{ threshold: 0 }">
                                <card class="hpos-card text-center rounded-4" :body=false>
                                    <img :src="'/images/home/new_welcome/hpos-hands.webp'" class="card-img rounded-4"
                                        alt="">
                                </card>
                            </aside>
                        </column>
                    </row>
                    <div class="m-2">
                        <h1 class="hero-heading uppercase mb-lh">{{ $t('public.new_welcome.what_is_expected.title') }}
                        </h1>
                        <p class="lead mb-lh" v-t="'public.new_welcome.what_is_expected.p1'"></p>
                        <p class="" v-t="'public.new_welcome.what_is_expected.p2'"></p>
                        <p class="" v-t="'public.new_welcome.what_is_expected.p3'"></p>
                        <div class="d-flex">
                            <btn is-router-link :to="{ name: 'register' }"
                                class="ms-auto mt-auto d-flex justify-content-between align-items-center rounded-pill">
                                <div class="fs-1 mx-3" v-t="'login.register'"></div>
                                <fa icon="circle-arrow-right" size="3x" />
                            </btn>
                        </div>
                    </div>
                </column>
                <column :sizes="{ lg: 12, xl: 5 }" class="order-1 order-xl-0">
                    <div class="m-2">
                        <p class="lead mb-lh" v-t="'public.new_welcome.what_is_expected.p4'"></p>
                        <ul class="ps-2 mb-lh" :aria-label="$t('public.new_welcome.what_is_expected.p4')">
                            <li class="d-flex align-items-center mb-4 fade-in mb-lh"
                                v-scroll-trigger="{ threshold: 1 }">
                                <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                    <use :href="'/images/home/icon-sprite.svg#check-circle'"></use>
                                </svg>
                                <p class="fw-bold m-0" v-t="'public.new_welcome.what_is_expected.steps_list.1'">
                                </p>
                            </li>
                            <li class="d-flex align-items-center  mb-2 fade-in mb-lh"
                                v-scroll-trigger="{ threshold: 1 }">
                                <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                    <use :href="'/images/home/icon-sprite.svg#check-circle'"></use>
                                </svg>
                                <p class="fw-bold m-0" v-t="'public.new_welcome.what_is_expected.steps_list.2'">
                                </p>
                            </li>
                            <li class="d-flex align-items-center  mb-2 fade-in mb-lh"
                                v-scroll-trigger="{ threshold: 1 }">
                                <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                    <use :href="'/images/home/icon-sprite.svg#check-circle'"></use>
                                </svg>
                                <p class="fw-bold m-0" v-t="'public.new_welcome.what_is_expected.steps_list.3'">
                                </p>
                            </li>
                        </ul>
                        <p class="lead mb-lh" v-t="'public.new_welcome.what_is_expected.p5'"></p>
                        <ul class="ps-2 mb-lh" :aria-label="$t('public.new_welcome.what_is_expected.p5')">
                            <li class="d-flex align-items-center mb-4 fade-in mb-lh"
                                v-scroll-trigger="{ threshold: 1 }">
                                <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                    <use :href="'/images/home/icon-sprite.svg#check-circle'"></use>
                                </svg>
                                <p class="fw-bold m-0" v-t="'public.new_welcome.what_is_expected.benefit_list.1'">
                                </p>
                            </li>
                            <li class="d-flex align-items-center mb-4 fade-in mb-lh"
                                v-scroll-trigger="{ threshold: 1 }">
                                <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                    <use :href="'/images/home/icon-sprite.svg#check-circle'"></use>
                                </svg>
                                <p class="fw-bold m-0" v-t="'public.new_welcome.what_is_expected.benefit_list.2'">
                                </p>
                            </li>
                        </ul>
                    </div>
                </column>
            </row>
        </section>
        <section id="find_out_more" class="section-5 scroll-section">
            <div class="m-2">
                <h1 class="hero-heading uppercase mb-lh">{{ $t('public.new_welcome.find_out_more.title') }}</h1>
                <p class="lead mb-lh" v-t="'public.new_welcome.find_out_more.p1'"></p>
            </div>
            <row>
                <column :sizes="{ md: 6, lg: 6, xl: 4 }" class="mb-4">
                    <card class="hpos-outline rounded-4 h-100" :body="false">
                        <div class="card-body text-center">
                            <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-5x fa-fw mb-4">
                                <use :href="'/images/home/icon-sprite.svg#PIS'"></use>
                            </svg>
                            <p class="lead " v-t="'public.new_welcome.find_out_more.cards.pis.title'"></p>
                            <p v-t="'public.new_welcome.find_out_more.cards.pis.p1'"></p>
                        </div>
                        <list-group flush>
                            <list-group-item v-if="activeCountry" class="d-flex ps-2 align-items-center">
                                <svg class="flag-img me-2 rounded" :alt="getCountryLang(activeCountry).name">
                                    <use :href="'/images/home/flags-sprite.svg#' + activeCountry.code.toLowerCase()">
                                    </use>
                                </svg>
                                <div class="me-auto">
                                    {{ getCountryLang(activeCountry).name }}
                                </div>
                                <a target="_blank" :href="activeCountry.pis_path" class="icon-link">
                                    <svg style="fill: currentColor" class="svg-inline--fa fa-1x fa-fw">
                                        <use :href="'/images/home/icon-sprite.svg#PIS'"></use>
                                    </svg>
                                    <span v-t="'public.more-info.more_info.PIS.linkLabel'"></span>
                                </a>
                            </list-group-item>
                            <list-group-item v-for="country in inactiveCountries" :key="'div_' + country.code"
                                class="d-flex  ps-2 align-items-center">
                                <svg class="flag-img me-2 rounded" :alt="getCountryLang(country).name">
                                    <use :href="'/images/home/flags-sprite.svg#' + country.code.toLowerCase()">
                                    </use>
                                </svg>


                                <div class="me-auto">
                                    {{ getCountryLang(country).name }}
                                </div>
                                <a target="_blank" :href="country.pis_path" class="icon-link">
                                    <svg style="fill: currentColor" class="svg-inline--fa fa-1x fa-fw">
                                        <use :href="'/images/home/icon-sprite.svg#PIS'"></use>
                                    </svg>
                                    <span v-t="'public.more-info.more_info.PIS.linkLabel'"></span>
                                </a>

                            </list-group-item>
                        </list-group>
                    </card>
                </column>
                <column :sizes="{ md: 6, lg: 6, xl: 4 }" class="mb-4">
                    <card class="hpos-outline rounded-4 h-100 " :body="false">
                        <div class="card-body text-center">
                            <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-5x fa-fw mb-4">
                                <use :href="'/images/home/icon-sprite.svg#Consent'"></use>
                            </svg>
                            <p class="lead " v-t="'public.new_welcome.find_out_more.cards.consent.title'"></p>
                            <p v-t="'public.new_welcome.find_out_more.cards.consent.p1'"></p>
                        </div>
                        <list-group flush>
                            <list-group-item v-if="activeCountry" class="d-flex ps-2 align-items-center">
                                <svg class="flag-img me-2 rounded" :alt="getCountryLang(activeCountry).name">
                                    <use :href="'/images/home/flags-sprite.svg#' + activeCountry.code.toLowerCase()">
                                    </use>
                                </svg>
                                <div class="me-auto">
                                    {{ getCountryLang(activeCountry).name }}
                                </div>
                                <a target="_blank" :href="activeCountry.consent_path" class="icon-link">
                                    <svg style="fill: currentColor" class="svg-inline--fa fa-1x fa-fw">
                                        <use :href="'/images/home/icon-sprite.svg#Consent'"></use>
                                    </svg>
                                    <span v-t="'public.more-info.more_info.Consent.linkLabel'"></span>
                                </a>
                            </list-group-item>
                            <list-group-item v-for="country in inactiveCountries" :key="'div_' + country.code"
                                class="d-flex  ps-2 align-items-center">
                                <svg class="flag-img me-2 rounded" :alt="getCountryLang(country).name">
                                    <use :href="'/images/home/flags-sprite.svg#' + country.code.toLowerCase()">
                                    </use>
                                </svg>
                                <div class="me-auto">
                                    {{ getCountryLang(country).name }}
                                </div>
                                <a target="_blank" :href="country.consent_path" class="icon-link">
                                    <svg style="fill: currentColor" class="svg-inline--fa fa-1x fa-fw">
                                        <use :href="'/images/home/icon-sprite.svg#Consent'"></use>
                                    </svg>
                                    <span v-t="'public.more-info.more_info.Consent.linkLabel'"></span>
                                </a>

                            </list-group-item>
                        </list-group>
                    </card>

                </column>
                <column :sizes="{ md: 12, lg: 12, xl: 4 }" class="mb-4">
                    <card class="hpos-outline rounded-4 h-100 " :body="false">
                        <div class="card-body text-center">
                            <div class="d-flex flex-column align-items-center h-100">
                                <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-5x fa-fw mb-4">
                                    <use :href="'/images/home/icon-sprite.svg#BloodStudy'"></use>
                                </svg>
                                <p class="lead " v-t="'public.new_welcome.find_out_more.cards.blood_study.title'"></p>
                                <p v-t="'public.new_welcome.find_out_more.cards.blood_study.p1'"></p>
                                <i18n path="public.new_welcome.find_out_more.cards.blood_study.link.text"
                                    class="mt-auto">
                                    <template #link>
                                        <a :href="$t('public.new_welcome.find_out_more.cards.blood_study.link.url')"
                                            v-t="'public.new_welcome.find_out_more.cards.blood_study.link.url'"></a>
                                    </template>
                                </i18n>
                            </div>
                        </div>
                    </card>
                </column>
            </row>
            <row>
                <column :sizes="{ md: 8 }" :offset="{ md: 2 }">
                    <div class="mx-2 d-flex flex-wrap align-items-center justify-content-between">
                        <span class="lead" v-t="'public.new_welcome.find_out_more.p2'"></span>
                        <a :href="$t('public.new_welcome.find_out_more.social.facebook.url')" class="ms-2"
                            target="_blank" :alt="$t('public.new_welcome.find_out_more.social.facebook.text')">
                            <svg style="fill: var(--primary-color-dark)" class="svg-inline--fa fa-2x fa-fw">
                                <use :href="'/images/home/icon-sprite.svg#fb'"></use>
                            </svg>
                        </a>
                        <a :href="$t('public.new_welcome.find_out_more.social.instagram.url')" class="ms-2"
                            target="_blank" :alt="$t('public.new_welcome.find_out_more.social.instagram.text')">
                            <svg style="fill: var(--primary-color-dark)" class="svg-inline--fa fa-2x fa-fw">
                                <use :href="'/images/home/icon-sprite.svg#insta'"></use>
                            </svg>
                        </a>
                        <a :href="$t('public.new_welcome.find_out_more.social.linkedin.url')" class="ms-2"
                            target="_blank" :alt="$t('public.new_welcome.find_out_more.social.linkedin.text')">
                            <svg style="fill: var(--primary-color-dark)" class="svg-inline--fa fa-2x fa-fw">
                                <use :href="'/images/home/icon-sprite.svg#linkedin'"></use>
                            </svg>
                        </a>
                        <a :href="$t('public.new_welcome.find_out_more.social.x.url')" class="ms-2" target="_blank"
                            :alt="$t('public.new_welcome.find_out_more.social.x.text')">
                            <svg style="fill: var(--primary-color-dark)" class="svg-inline--fa fa-2x fa-fw">
                                <use :href="'/images/home/icon-sprite.svg#x'"></use>
                            </svg>
                        </a>
                        <a :href="$t('public.new_welcome.who_are_we.link.url')" class="ms-2" target="_blank"
                            :alt="$t('public.new_welcome.who_are_we.link.text')">
                            <svg style="fill: var(--primary-color-dark)" class="svg-inline--fa fa-2x fa-fw">
                                <use :href="'/images/home/icon-sprite.svg#globe'"></use>
                            </svg>
                            <span class="lead" v-t="'public.new_welcome.who_are_we.link.url'"></span>

                        </a>
                    </div>
                </column>
            </row>
        </section>
        <section id="contact" class="section-6 scroll-section mb-5">
            <div style="min-height:80vh">
                <row class="my-4">
                    <!-- Contact -->

                    <column :sizes="{ lg: 4, xl: 4 }" class="">
                        <aside v-scroll-trigger="{ threshold: 0 }">
                            <card class="hpos-card text-center rounded-4 mb-5" :body=false>
                                <img :src="'/images/home/new_welcome/hpos-speech-bubble.webp'"
                                    class="card-img rounded-4" alt="">
                            </card>
                        </aside>
                        <div class="mx-2">
                            <h1 class="hero-heading uppercase">{{ $t('public.new_welcome.contact.title') }}</h1>
                            <h1 class="hero-heading highlight uppercase">{{ $t('public.new_welcome.contact.title_2') }}
                            </h1>
                            <p class="lead mb-lh" v-t="'public.new_welcome.contact.p1'"></p>
                            <p class="lead mb-lh" v-t="'public.new_welcome.contact.p2'"></p>
                            <btn is-router-link :to="{ name: 'register' }"
                                class="mt-auto d-flex justify-content-between align-items-center rounded-pill">
                                <div class="fs-1 ms-3" v-t="'login.register'"></div>
                                <fa icon="circle-arrow-right" size="3x" />
                            </btn>
                        </div>
                    </column>
                    <column class="py-4">
                        <card class="hpos-outline rounded-4 h-100 p-xl-4" :body="false">
                            <ul class="list-group list-group-flush justify-content-around h-100">
                                <li class="list-group-item d-flex align-items-center border-0">
                                    <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                        <use :href="'/images/home/icon-sprite.svg#click'"></use>
                                    </svg>
                                    <p class="lead" v-t="'public.new_welcome.contact.steps.1'"></p>
                                </li>
                                <li class="list-group-item d-flex align-items-center border-0">
                                    <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                        <use :href="'/images/home/icon-sprite.svg#register'"></use>
                                    </svg>
                                    <p class="lead" v-t="'public.new_welcome.contact.steps.2'"></p>
                                </li>
                                <li class="list-group-item d-flex align-items-center border-0">
                                    <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                        <use :href="'/images/home/icon-sprite.svg#infosheet'"></use>
                                    </svg>
                                    <p class="lead" v-t="'public.new_welcome.contact.steps.3'"></p>
                                </li>
                                <li class="list-group-item d-flex align-items-center border-0">
                                    <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                        <use :href="'/images/home/icon-sprite.svg#contact'"></use>
                                    </svg>
                                    <p class="lead" v-t="'public.new_welcome.contact.steps.4'"></p>
                                </li>
                                <li class="list-group-item d-flex align-items-center border-0">
                                    <svg style="fill: var(--secondary-color);" class="svg-inline--fa fa-3x fa-fw me-4">
                                        <use :href="'/images/home/icon-sprite.svg#check-circle'"></use>
                                    </svg>
                                    <p class="lead" v-t="'public.new_welcome.contact.steps.5'"></p>
                                </li>
                            </ul>
                        </card>
                    </column>
                </row>
                <div class="mx-2">
                    <i18n path="public.new_welcome.contact.contact_us"
                        class="lead d-flex flex-wrap align-items-center my-4" tag="p">
                        <template #email>
                            <a :href="'mailto:' + $t('public.new_welcome.contact.email')"
                                class="btn btn-primary btn-large ms-auto d-flex justify-content-between align-items-center rounded-pill"
                                style="--bs-btn-bg:var(--primary-color)">
                                <div class="fs-3 mx-3" v-t="'public.new_welcome.contact.email'"></div>
                                <fa icon="circle-arrow-right" size="3x" />
                            </a>
                        </template>
                    </i18n>
                </div>

            </div>

            <div class="mx-2 my-4" style="min-height:40vh">
                <h1 class="hero-heading uppercase">{{ $t('public.new_welcome.who_funds_us.title') }}</h1>
                <p class="lead" v-t="'public.new_welcome.who_funds_us.p1'"></p>
                <p class="lead" v-t="'public.new_welcome.who_funds_us.p2'"></p>
                <p class="lead" v-t="'public.new_welcome.who_funds_us.p3'"></p>

            </div>

            <row class="mx-2 my-4">
                <column :sizes="{ md: 8 }" :offset="{ md: 2 }">
                    <div class="d-flex flex-wrap align-items-center justify-content-between">
                        <span class="lead" v-t="'public.new_welcome.find_out_more.p2'"></span>
                        <a :href="$t('public.new_welcome.find_out_more.social.facebook.url')" class="ms-2"
                            target="_blank" :alt="$t('public.new_welcome.find_out_more.social.facebook.text')">
                            <svg class="svg-inline--fa fa-2x fa-fw">
                                <use :href="'/images/home/icon-sprite.svg#fb'"></use>
                            </svg>
                        </a>
                        <a :href="$t('public.new_welcome.find_out_more.social.instagram.url')" class="ms-2"
                            target="_blank" :alt="$t('public.new_welcome.find_out_more.social.instagram.text')">
                            <svg class="svg-inline--fa fa-2x fa-fw">
                                <use :href="'/images/home/icon-sprite.svg#insta'"></use>
                            </svg>
                        </a>
                        <a :href="$t('public.new_welcome.find_out_more.social.linkedin.url')" class="ms-2"
                            target="_blank" :alt="$t('public.new_welcome.find_out_more.social.linkedin.text')">
                            <svg class="svg-inline--fa fa-2x fa-fw">
                                <use :href="'/images/home/icon-sprite.svg#linkedin'"></use>
                            </svg>
                        </a>
                        <a :href="$t('public.new_welcome.find_out_more.social.x.url')" class="ms-2" target="_blank"
                            :alt="$t('public.new_welcome.find_out_more.social.x.text')">
                            <svg class="svg-inline--fa fa-2x fa-fw">
                                <use :href="'/images/home/icon-sprite.svg#x'"></use>
                            </svg>
                        </a>
                        <a :href="$t('public.new_welcome.who_are_we.link.url')" class="ms-2" target="_blank"
                            :alt="$t('public.new_welcome.who_are_we.link.text')">
                            <svg class="svg-inline--fa fa-2x fa-fw">
                                <use :href="'/images/home/icon-sprite.svg#globe'"></use>
                            </svg>
                            <span class="lead" v-t="'public.new_welcome.who_are_we.link.url'"></span>

                        </a>
                    </div>
                </column>
            </row>

        </section>


        <card class="hpos-outline text-center">
            <h2 v-t="'public.home.partners.title'"></h2>
            <template v-for="p in $t('public.home.partners.list')"><a :href="p.url" :key="p.url" class="m-2"><img
                        :src="p.img" :alt="p.title" class="m-2" style="max-height:100px;"></a></template>
            <h3 v-t="'public.home.special_thanks.title'"></h3>
            <template v-for="p in $t('public.home.special_thanks.list')">
                <a :href="p.url" target="_blank" class="m-2" :key="p.url">
                    <img :src="p.img" :alt="p.title" class="m-2" :width="p.width">
                </a>
            </template>
        </card>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import GeoMap from '~/components/charts/geo-map.vue'
import countryMixin from '~/mixins/country';
export default {
    name: 'new_welcome',
    metaInfo() {
        return { title: this.$t('public.new_welcome.short_title') }
    },
    components: {
        GeoMap
    },
    mixins: [countryMixin],
    mounted() {
        this.getPublicPatientCount()
        this.getPublicCountries()
    },
    data() {
        return {
            hoveringFlag: false,
            videoId: 'szVHRFjm5vk'
        }
    },
    computed: {
        ...mapState('resources/patient', ['public']),
        ...mapState('resources/countries', ['countries']),
        patientCount() {
            return this.public.count
        },

        activeCountry() {
            if (!this.countries) return
            return this.countries.find(function (country) {
                return country.code == window.config.country.countryCode;
            });
        },
        inactiveCountries() {
            if (!this.countries) return
            return this.countries.filter(function (country) {
                return country.code != window.config.country.countryCode;
            });
        },
        nodes() {
            var arr = []
            var count = this.patientCount > 1000 ? 1000 : this.patientCount
            for (var i = 0; i < count; i++) arr.push({})
            return arr
        },
        sections() {
            return [
                { id: 'study', title: this.$t('public.new_welcome.sections.study.title') },
                { id: 'eligibility', title: this.$t('public.new_welcome.sections.eligibility.title') },
                { id: 'who_are_we', title: this.$t('public.new_welcome.sections.who_are_we.title') },
                { id: 'what_is_expected', title: this.$t('public.new_welcome.sections.what_is_expected.title') },
                { id: 'find_out_more', title: this.$t('public.new_welcome.sections.find_out_more.title') },
                { id: 'contact', title: this.$t('public.new_welcome.sections.contact.title') },
            ]
        }
    },
    methods: {

        ...mapActions('resources/patient', ['getPublicPatientCount']),
        ...mapActions('resources/countries', ['getPublicCountries']),
        hoverOnFlag(e, show) {
            this.hoveringFlag = e.target.id.replace('flag_', '')
            var countryPath = this.$el.querySelector('.europe-map').querySelector(`path[id="path_${this.hoveringFlag}"]`)
            if (!countryPath) return
            if (show) countryPath.classList.add('hovering')
            else countryPath.classList.remove('hovering')
        },
        isFeaturedCountry(name) {
            var aic = Object.values(this.$t('public.home.countries.ca_list', 'en'))
            var csc = Object.values(this.$t('public.home.countries.ls_list', 'en'))
            return aic.map(c => c.name).includes(name) || csc.map(c => c.name).includes(name)
        },
        featuredCountry(name) {
            var aic = Object.values(this.$t('public.home.countries.ca_list', 'en'))
            var csc = Object.values(this.$t('public.home.countries.ls_list', 'en'))
            return aic.find(c => c.name == name) || csc.find(c => c.name == name)
        },
    }
}
</script>
<style></style>