<template>
    <card>
        <h1 class="display-1 d-flex align-items-center">
            <fa icon="envelope-circle-check" class="m-4" />
            <div v-t="'public.keep-me-posted.title'"></div>
        </h1>
        <h2 v-t="'public.keep-me-posted.intro'"></h2>
        <p v-t="'public.home.countries.currently_available'" class="mx-4 mt-5"></p>
        <row class="g-1 mb-4">
            <column v-for="country in availableInCountries" :sizes="[6, { md: 6, lg: 6 }]" :key="country.countryCode">
                <card :body="false" class="country-card">
                    <row class="g-0 align-items-center">
                        <column :sizes="[3, { xs: 3, sm: 3, md: 3 }]">
                            <svg class="flag-img card-img rounded-left img-fluid"
                                :alt="country.countryCode.toLowerCase()">
                                <use :href="'/images/home/flags-sprite.svg#' + country.countryCode.toLowerCase()">
                                </use>
                            </svg>

                        </column>
                        <column :sizes="[9, { xs: 9, sm: 9, md: 9 }]">
                            <div class="card-body d-flex  align-items-center py-2">
                                {{ country.name }}
                            </div>
                        </column>
                    </row>
                </card>

            </column>
        </row>
        <column :sizes="{ xl: 12 }" v-if="!inAvailableCountry">
            <p v-t="'public.home.countries.launching_soon'" class="mx-4"></p>
            <row class="g-1 mb-4 justify-content-xl-between">
                <column v-for="country in comingSoonCountries" auto :sizes="[6, { sm: 4, md: 3 }]"
                    :key="country.countryCode">
                    <card :body="false" class="country-card">
                        <row class="g-1 align-items-center">
                            <column :sizes="[4, { xs: 4, sm: 4, lg: 5 }]">
                                <svg class="flag-img card-img rounded-left img-fluid"
                                    :alt="country.countryCode.toLowerCase()">
                                    <use :href="'/images/home/flags-sprite.svg#' + country.countryCode.toLowerCase()">
                                    </use>
                                </svg>
                            </column>
                            <column :sizes="[8, { xs: 8, sm: 8, lg: 7 }]">
                                <div class="card-body d-flex text-center px-2 py-1">
                                    {{ country.name }}
                                </div>
                            </column>
                        </row>
                    </card>
                </column>
            </row>
        </column>

        <p class="lead" v-t="'public.keep-me-posted.p1'"></p>
        <p v-t="'public.keep-me-posted.p2'"></p>
        <p v-t="'public.keep-me-posted.p3'"></p>
        <p v-t="'public.keep-me-posted.p4'"></p>
        <form @submit.prevent :class="{ 'was-validated': submitted }">
            <form-group :error="serverErrors.email_hash">
                <input-group>
                    <div class="input-group-text">{{ $t('public.keep-me-posted.email') }}</div>
                    <textbox autocomplete="off" type="email" v-model="email" required />
                    <button type="submit" outline @click="subscribe" @keydown.Enter="subscribe"
                        :class="['btn', 'btn-outline-primary', { 'btn-loading': submitting }]">{{
                            $t('public.keep-me-posted.submit') }}</button>
                </input-group>
            </form-group>
        </form>
        <alert class="m-4" type="primary" v-if="serverResponse">{{ serverResponse.message }} </alert>
        <hr>
        <socialShareButtons :meta="metaData" class="mt-4 fs-1"></socialShareButtons>

    </card>
</template>

<script>
import socialShareButtons from '~/components/social-share-buttons.vue'
import axios from 'axios'
export default {
    name: 'keep-me-posted',
    components: { socialShareButtons },
    metaInfo() {
        return { title: this.$t('public.keep-me-posted.title') }
    },
    data() {
        return {
            serverErrors: {},
            serverResponse: false,
            submitted: false,
            submitting: false,
            email: null,
            metaData: {
                title: this.$t('public.home.title'),
                description: this.$t('public.home.story_0.para_1'),
                url: window.location.href,
                image: window.location.href + '/images/hpos_logo.svg',
            },
        }
    },
    computed: {
        lp() {
            return 'public.keep-me-posted.'
        },
        availableInCountries() {
            return this.$t('public.home.countries.ca_list')
        },
        comingSoonCountries() {
            return this.$t('public.home.countries.ls_list')
        },
        location() {
            return window.config.country
        },
        inAvailableCountry() {
            return Object.values(this.availableInCountries).findIndex((c) => c.countryCode == this.location.countryCode) > -1
        },
    },
    methods: {
        subscribe() {
            this.submitted = true
            this.submitting = true
            axios.post('/keep-me-posted', { email: this.email })
                .then((resp) => {
                    this.submitting = false
                    this.serverResponse = resp.data
                    this.$gtag.event("form_submission", {
                        event_category: "Subscription",
                        event_label: "Keep Me Posted",
                    });
                })
                .catch((error) => {
                    this.serverErrors = error.response.data.errors
                    this.$gtag.event("form_error", {
                        event_category: "Subscription",
                        event_label: "Validation Error",
                        value: error.response.data.errors,
                    });
                })

        }
    }

}
</script>

<style lang="scss" scoped></style>