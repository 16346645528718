export default {
    computed: {
        countriesObject() {
            return this.$t('public.home.countries.ca_list')
        },
        countriesArr() {

            return Object.values(this.countriesObject)
        },
        availableInCountries() {
            return this.$t('public.home.countries.ca_list')
        },
        comingSoonCountries() {
            return this.$t('public.home.countries.ls_list')
        },
        countryColours() {
            // combine the available and coming soon countries, colour the available countries green and the coming soon countries red
            const colours = {}
            var aic = this.$t('public.home.countries.ca_list', 'en')
            var csc = this.$t('public.home.countries.ls_list', 'en')

            for (const [key, value] of Object.entries(aic)) {
                colours[value.name] = Object.keys(aic).includes(key) ? 'var(--primary-highlight-color)' : 'grey'
            }
            for (const [key, value] of Object.entries(csc)) {
                colours[value.name] = Object.keys(csc).includes(key) ? 'var(--secondary-color)' : 'grey'
            }
            // for (const [key, value] of Object.entries(this.availableInCountries)) {
            //     colours[value.name] = Object.keys(this.availableInCountries).includes(key) ? 'var(--primary-highlight-color)' : 'grey'
            // }
            // for (const [key, value] of Object.entries(this.comingSoonCountries)) {
            //     colours[value.name] = Object.keys(this.comingSoonCountries).includes(key) ? 'var(--secondary-color)' : 'grey'
            // }
            return colours

        },
        countryClasses() {
            const classes = {}
            var aic = this.$t('public.home.countries.ca_list', 'en')
            var csc = this.$t('public.home.countries.ls_list', 'en')

            for (const [key, value] of Object.entries(aic)) {
                classes[value.name] = Object.keys(aic).includes(key) ? 'available' : 'coming-soon'
            }
            for (const [key, value] of Object.entries(csc)) {
                classes[value.name] = Object.keys(csc).includes(key) ? 'coming-soon' : 'available'
            }
            // for (const [key, value] of Object.entries(this.availableInCountries)) {
            //     classes[value.name] = Object.keys(this.availableInCountries).includes(key) ? 'available' : 'coming-soon'
            // }
            // for (const [key, value] of Object.entries(this.comingSoonCountries)) {
            //     classes[value.name] = Object.keys(this.comingSoonCountries).includes(key) ? 'coming-soon' : 'available'
            // }
            return classes
        },
    },
    methods: {
        patientCountry(patient) {
            return this.countriesArr.find(country => country.id == patient.country_id)
        },
        getCountryLang(country) {
            var countryList = this.countriesObject
            // convert to an array
            countryList = Object.keys(countryList).map(key => {
                return {
                    id: countryList[key].id,
                    name: countryList[key].name
                }
            })

            return countryList.find(c => c.id === country.id)
        }
    }
}