<template>
    <div :class="{ 'container-xxl': !widescreen, 'mx-5': widescreen }" style="transition:all .3s ease;">
        <btn-group class="my-2">
            <router-link :to="{ name: 'admin.patients.registration' }"
                :class="['btn btn-outline-primary', { active: $route.name == 'admin.patients.registration' }]"
                active-class="active" exact v-t="'admin.patients.registration.title'"></router-link>
            <router-link :to="{ name: 'admin.patients' }"
                :class="['btn btn-outline-primary', { active: $route.name == 'admin.patients' }]" active-class="active"
                exact v-t="'admin.patients.title'"></router-link>
        </btn-group>
        <card head foot class="mb-4" :body="false">
            <template #header>
                <div class="card-header d-flex align-items-center">
                    <h4 v-if="$route.name == 'admin.patients'" class="mb-0 flex-grow-1" v-t="'admin.patients.title'">
                    </h4>
                    <h4 v-if="$route.name == 'admin.patients.consent'" class="mb-0 flex-grow-1"
                        v-t="'admin.patients.consent.title'"></h4>
                    <transition name="fade">
                        <fa icon="cog" spin size="2x" class="me-3" v-if="loadingPatients" />
                    </transition>
                    <btn v-model="showFilterRow" outline>
                        <fa icon="filter" />
                        <badge v-if="filtersApplied(filterList)" type="secondary" class="ms-1">
                            <span v-text="filtersApplied(filterList)"></span>
                        </badge>
                    </btn>
                    <!-- widescreen button -->
                    <btn outline v-model="widescreen" :true-value="true" :false-value="false" class="ms-1">
                        <fa icon="expand" />
                    </btn>
                </div>
            </template>
            <div class="card-header dt-row" v-if="showFilterRow">
                <fieldset name="filters">

                    <legend class="col-form-label pt-0 col-md-2" v-t="'admin.patients.filter.title'"></legend>
                    <row>
                        <form-group v-if="isAdmin"
                            :di="{ name: 'patients.country_id', label: $t('admin.patients.columns.country') }"
                            :sizes="{ md: 6, lg: 4, xl: 2 }" class="my-1">

                            <dropdown justified btn-outline :items="countriesArr"
                                v-model="filters['patients.country_id']" value-name="id"
                                @input="makeRequest({}, pagination)" label-name="name" multiple>
                                <template #list-item-prepend="{ item }">
                                    <svg class="flag-img rounded-left img-fluid ms-n1 me-1"
                                        :alt="item.countryCode.toLowerCase()" style="width:24px;">
                                        <use :href="'/images/home/flags-sprite.svg#' + item.countryCode.toLowerCase()">
                                        </use>
                                    </svg>

                                </template>
                            </dropdown>

                        </form-group>
                        <form-group :di="{ name: 'code', label: $t('admin.patients.columns.hpos_code') }"
                            :sizes="{ md: 6, lg: 4, xl: 3 }" class="my-1">
                            <input-group>
                                <template #prepend>
                                    <div class="input-group-text">HPOS -</div>
                                </template>
                                <!-- mask should be 00-0000 -->
                                <textbox type="text" v-model="filters['patients.code']" v-mask="{
                                    mask: '##-####',
                                    pattern: /[0-9]/
                                }"></textbox>
                                <template #append v-if="filters['patients.code']">
                                    <btn @click.native="makeRequest({}, pagination)" v-t="'admin.patients.search.title'"
                                        size="sm"></btn>
                                    <btn btn-class="secondary"
                                        @click.native="filters['patients.code'] = null; makeRequest({}, pagination)"
                                        size="sm" v-t="'admin.patients.search.clear'"></btn>
                                </template>
                            </input-group>
                        </form-group>
                        <form-group :di="{ name: 'users.email', label: $t('admin.patients.columns.email') }"
                            :sizes="{ md: 6, lg: 4, xl: 3 }" class="my-1">
                            <input-group>
                                <textbox type="email" required v-model="filters['users.email']"></textbox>
                                <template #append v-if="filters['users.email']">
                                    <btn @click.native="makeRequest({}, pagination)"
                                        v-t="'admin.patients.search.title'">
                                    </btn>
                                    <btn btn-class="secondary"
                                        @click.native="filters['users.email'] = null; makeRequest({}, pagination)"
                                        v-t="'admin.patients.search.clear'"></btn>
                                </template>
                            </input-group>
                        </form-group>
                        <form-group v-if="$route.name == 'admin.patients'"
                            :di="{ name: 'issues', label: $t('admin.patients.columns.issues') }"
                            :sizes="{ md: 6, lg: 6, xl: 4 }" class="my-1">
                            <btn-group justified>
                                <btn outline v-model="filters['hasCurrentIssueIdComplete']" :true-value="true"
                                    :false-value="false"
                                    @click.native="toggleConnectedFilter('hasCurrentIssueIdComplete', 'hasCurrentIssueIdIncomplete', pagination)"
                                    v-t="'admin.patients.filters.hasCurrentIssueIdComplete'">
                                </btn>
                                <btn outline v-model="filters['hasCurrentIssueIdIncomplete']" :true-value="true"
                                    :false-value="false"
                                    @click.native="toggleConnectedFilter('hasCurrentIssueIdIncomplete', 'hasCurrentIssueIdComplete', pagination)"
                                    v-t="'admin.patients.filters.hasCurrentIssueIdIncomplete'">
                                </btn>
                            </btn-group>
                        </form-group>

                        <form-group v-if="$route.name == 'admin.patients'"
                            :di="{ name: 'hasUnverifiedDocuments', label: $t('admin.patients.columns.documents') }"
                            :sizes="{ md: 6, lg: 6, xl: 4 }" class="my-1">
                            <btn-group justified>
                                <btn outline v-model="filters['hasVerifiedDocuments']" :true-value="true"
                                    :false-value="false"
                                    @click.native="toggleConnectedFilter('hasVerifiedDocuments', 'hasUnverifiedDocuments', pagination)"
                                    v-t="'admin.patients.filters.hasVerifiedDocuments'">
                                </btn>
                                <btn outline v-model="filters['hasUnverifiedDocuments']" :true-value="true"
                                    :false-value="false"
                                    @click.native="toggleConnectedFilter('hasUnverifiedDocuments', 'hasVerifiedDocuments', pagination)"
                                    v-t="'admin.patients.filters.hasUnverifiedDocuments'">
                                </btn>
                            </btn-group>
                        </form-group>
                        <!-- hasPsADiagnosis -->
                        <form-group v-if="$route.name == 'admin.patients'" :di="{ name: 'hasPsADiagnosis' }"
                            :sizes="{ md: 3, lg: 3, xl: 2 }" class="my-1">
                            <btn outline v-model="filters['hasPsADiagnosis']" :true-value="true" :false-value="false"
                                @click.native="makeRequest({}, pagination)"
                                v-t="'admin.patients.filters.hasPsADiagnosis'">
                            </btn>
                        </form-group>
                    </row>
                </fieldset>
            </div>
            <data-table :data="patients" :columns="patientColumns" hide-index
                :class="['patients-datatable table-responsive', { loading: loadingPatients }]">
                <template #header-column="{ col }">
                    <div v-if="col.sortable" @click="setSorting(sortField(col.name), pagination)"
                        :class="['dt-column dt-column-header dt-column-' + col.name, sortingClass({ name: sortField(col.name) })]">
                        <div v-if="col.section != undefined" class="p-2" />
                        <div v-else class="p-2" v-text="col.label" />
                    </div>
                    <div v-else :class="['dt-column dt-column-header dt-column-' + col.name + '']">
                        <div v-if="col.section != undefined" class="p-2" />
                        <div v-else class="p-2" v-text="col.label" />
                    </div>
                </template>
                <template #cell-country="{ rowData }">
                    <div class="p-2">
                        <svg class="flag-img rounded-left img-fluid me-1 mb-1"
                            :alt="patientCountry(rowData).countryCode.toLowerCase()" style="width:26px;">
                            <use
                                :href="'/images/home/flags-sprite.svg#' + patientCountry(rowData).countryCode.toLowerCase()">
                            </use>
                        </svg>


                        <span v-if="rowData.country_id">
                            {{ patientCountry(rowData).countryCode }}
                        </span>
                    </div>
                </template>
                <template #cell-name="{ rowData }">
                    <div class="p-2 align-items-center"
                        v-text="rowData.user ? rowData.user.first_name + ' ' + rowData.user.last_name : ''" />
                </template>
                <template #cell-email="{ rowData }">
                    <div class="p-2" v-text="rowData.user ? rowData.user.email : ''" />
                </template>
                <template #cell-consent="{ rowData }">
                    <div class="d-flex p-1 align-items-center">
                        <span class="me-2" v-if="rowData.consentDate && rowData.isConsented">
                            {{ $d(new Date(rowData.consentDate), 'numeric', 'en-GB') }}
                        </span>
                        <div class="m-auto position-relative">
                            <small :key="rowData.id + '-RegIncompleteNotificationBadge'">
                                <badge v-if="rowData.registrationRemindersSent" pill class="notification-badge  ms-n2">
                                    <fa icon="envelope" class="me-1" />
                                    {{ rowData.registrationRemindersSent }}
                                </badge>
                            </small>
                            <btn size="sm" outline :theme="rowData.isConsented ? 'primary' : 'danger'" is-router-link
                                :to="{ name: 'admin.consent', params: { patient_id: rowData.id } }" class="m-auto p-1">
                                <fa :icon="rowData.isConsented ? 'check' : 'times'" fixed-width />
                            </btn>
                        </div>

                    </div>
                </template>
                <template #cell-consentItem="{ rowData, col }">
                    <fa v-if="col.section == rowData.consent.consentType"
                        :icon="rowData.consent[rowData.consent.consentType][0][col.label] == 1 ? 'check' : 'times'"
                        fixed-width />
                </template>
                <template #column-issues="{ rowData }">
                    <div class="dt-column dt-column-issues overflow-visible">
                        <div class="p-1 text-center d-flex m-auto ">
                            <template v-if="rowData.issuesSummary && rowData.issuesSummary.length">
                                <template v-for="issueSummary in rowData.issuesSummary">
                                    <div :key="rowData.id + '-' + issueSummary.id + 'progress'"
                                        class="position-relative">
                                        <small>
                                            <badge v-if="issueSummary.notifications_sent" pill
                                                class="notification-badge" theme="secondary">
                                                <fa icon="envelope" class="me-1" />
                                                {{ issueSummary.notifications_sent }}
                                            </badge>
                                        </small>
                                        <donut-progress v-if="issueSummary.pc_complete" :id="rowData.id + 'donut'"
                                            :class="['donut me-1 text-muted', { 'complete': percentageComplete(issueSummary) == 100 }]"
                                            :label="percentageComplete(issueSummary)"
                                            :chart-data="formatChartData(issueSummary)" :width="36" :height="36"
                                            :background-colour="percentageComplete(issueSummary) == 100 ? 'var(--bs-success)' : 'var(--bs-gray-200)'"
                                            v-popover="{ title: issueSummaryPopoverTitle(issueSummary, rowData), event: 'hover', popoverComponentSelector: '#popover-component', body: issueSummaryPopoverBody(issueSummary, rowData) }"></donut-progress>
                                    </div>
                                    <div v-if="rowData.nextIssueDue && new Date(rowData.nextIssueDue) < new Date()"
                                        :key="rowData.id + '-' + issueSummary.id + 'issueDue'" class="me-2"
                                        style="color:var(--bs-gray-600);">
                                        <fa icon="clock" size="2x"></fa>
                                    </div>
                                </template>
                            </template>

                            <template v-if="rowData.nextIssueDue">
                                <div class="mt-1" style="color:var(--bs-gray-600);"
                                    v-popover="{ event: 'hover', popoverComponentSelector: '#popover-component', body: $d(new Date(rowData.nextIssueDue), 'short', 'en-GB') }">
                                    <fa icon="calendar" size="xl" />
                                </div>

                            </template>
                        </div>
                    </div>
                </template>
                <template #cell-notifications="{ rowData }">
                    <div class="p-2">
                        <fa icon="envelope" size="xl" class="me-1"
                            v-if="rowData.user && rowData.user.recentNotificationCount > 0" />
                        <badge v-if="rowData.user && rowData.user.recentNotificationCount > 0">
                            <span v-text="rowData.user.recentNotificationCount"></span>
                        </badge>

                    </div>
                </template>
                <template #cell-activity="{ rowData }">
                    <div class="p-2" v-if="rowData.user && rowData.user.latestActivity">
                        {{ new Date(rowData.user.latestActivity.created_at).toLocaleString('en-gb') }}
                    </div>
                </template>
                <template #cell-documents="{ rowData }">
                    <div class="p-2">
                        <fa icon="file-alt" size="xl"
                            :class="['me-1', { 'text-success': rowData.hasVerifiedDocuments, 'text-danger': rowData.hasUnverifiedDocuments }]"
                            v-if="rowData.hasVerifiedDocuments || rowData.hasUnverifiedDocuments" />
                        <badge v-if="rowData.hasVerifiedDocuments" theme="success" pill class="me-1">
                            <fa icon="check" class="me-1" />
                        </badge>
                    </div>
                </template>
                <template #cell-edit="{ rowData }">
                    <div class="d-flex p-1 align-items-center m-auto">
                        <btn outline size="sm" is-router-link
                            :to="{ name: 'admin.personal_details', params: { patient_id: rowData.id } }" class="m-auto">
                            <fa icon="user" size="lg" fixed-width />
                        </btn>
                    </div>
                </template>
                <template #cell-view="{ rowData }">
                    <div class="d-flex p-1 align-items-center m-auto">
                        <btn outline size="sm" :key="'patient_overview-btn-' + rowData.id" is-router-link
                            :to="{ name: 'admin.patient_overview', params: { patient_id: rowData.id } }" block>
                            <fa icon="play" size="lg" fixed-width />
                        </btn>
                    </div>
                </template>
                <popover id="popover-component" show>
                    <template #title>Original Popover title</template>
                    <template #body>Original content</template>
                </popover>
            </data-table>
            <template #footer-inner>
                <div class="d-flex pt-2">
                    <ul class="pagination m-auto">
                        <li v-for="(link, n) in pagination.links" :key="'pagination' + n"
                            :class="['page-item', { active: link.active, disabled: !link.url }]">
                            <a @click="updatePagination(link, pagination)"
                                :class="['page-link', { 'bg-dark text-white': $root.theme == 'dark' && !link.active }]"
                                v-html="link.label" />
                        </li>
                    </ul>
                    <div class="m-auto">
                        <span
                            v-t="{ path: 'settings.showing', args: { from: pagination.from, to: pagination.to, total: pagination.total } }"></span>
                    </div>
                    <dropdown btn-outline v-model="pagination.per_page" :items="paginationPerPageList" class="m-auto"
                        @input="updatePerPage(pagination)">
                        <template #btn-inner="{ chosenLabel }">
                            <span v-t="{ path: 'settings.per_page', args: { count: chosenLabel } }"></span>
                        </template>
                    </dropdown>
                </div>
            </template>
        </card>
    </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import paginationMixin from '~/mixins/pagination'
import patientIssueMixin from '~/mixins/patient_issue'
import countryMixin from '~/mixins/country'
export default {
    name: "consent-overview",
    metaInfo() {
        return { title: this.$t('admin.patients.title') }
    },
    layout: 'fullscreen',
    middleware: ['auth', 'team'],
    mixins: [countryMixin, paginationMixin, patientIssueMixin],
    apiMethod: 'getPatients',
    data() {
        return {
            showFilterRow: false,
            widescreen: this.getLocalStorage('widescreen') ? this.getLocalStorage('widescreen') : false,
            filters: {
                'users.email': null,
                'patients.country_id': [],
                'patients.code': null,
                'consented': true,
                'hasCurrentIssueIdComplete': false,
                'hasCurrentIssueIdIncomplete': false,
                'hasPsADiagnosis': false,
                'hasVerifiedDocuments': false,
                'hasUnverifiedDocuments': false

            },
            params: {
                include: 'consent',
                append: '',
                sort: this.getLocalStorage('sort') ? this.getLocalStorage('sort') : 'patients.id',
                issues_include: 'questionnairesSummary',
            },
            QParams: {
                name: 'consent',
                include: 'dataitems',
                append: 'lookups,validation'

            },
        }
    },
    mounted() {
        var params = this.params
        this.getQuestionnaire({ name: 'consent', params: this.QParams })
        this.makeRequest({}, this.pagination);
    },
    computed: {
        ...mapGetters('auth', ['isAdmin']),
        ...mapState('resources/patient', ['patients', 'pagination', 'loadingPatients']),
        ...mapState("resources/questionnaires", ["questionnaire"]),
        consentColumns() {
            return this.questionnaire.dataitems.filter((item) => item.section != null).map((item) => {
                return {
                    name: 'consentItem',
                    label: item.name,
                    section: item.section,
                    Type: item.type,
                    sortable: true,
                    width: 'max-content'
                }
            })
        },
        patientColumns() {
            return [
                { name: 'id', label: this.$t('admin.patients.columns.id'), Type: Number, sortable: true, width: 'min-content' },
                this.isAdmin && { name: 'country', label: this.$t('admin.patients.columns.country'), Type: Number, sortable: true, width: 'max-content' },
                { name: 'code', label: this.$t('admin.patients.columns.hpos_code'), Type: Number, sortable: true, width: 'max-content' },
                { name: 'name', label: this.$t('admin.patients.columns.name'), Type: String, width: 'max-content' },
                { name: 'consent', label: this.$t('admin.patients.columns.consent'), Type: Number, width: 'max-content' },
                this.$route.name == 'admin.patients' && { name: 'issues', label: this.$t('admin.patients.columns.issues'), Type: String, sortable: true, width: 'max-content' },
                //{ name: 'notifications', label: this.$t('admin.patients.columns.notification_count'), Type: String, sortable: true, width: 'max-content' },
                this.$route.name == 'admin.patients' && { name: 'activity', label: this.$t('admin.patients.columns.activity'), Type: String, sortable: true, width: 'max-content' },
                this.$route.name == 'admin.patients' && (this.filters['hasVerifiedDocuments'] || this.filters['hasUnverifiedDocuments'] || this.filters['hasPsADiagnosis']) && { name: 'documents', label: this.$t('admin.patients.columns.documents'), Type: String, sortable: true, width: 'max-content' },
                ...this.consentColumns,
                { name: 'edit', label: this.$t('admin.patients.columns.edit'), Type: null, width: 'max-content' },
                { name: 'view', label: this.$t('admin.patients.columns.view'), Type: null, width: 'max-content' },
            ].filter(Boolean)
        },
        filterList() {
            return Object.keys(this.filters).filter(key => !['consented', 'notConsented'].includes(key))
        },
    },
    methods: {
        ...mapActions('resources/patient', ['getPatients']),
        ...mapActions('resources/questionnaires', ['getQuestionnaire']),
        updateFilters() {
            this.filters['consented'] = true
            this.filters['hasCurrentIssueIdComplete'] = false
            this.filters['hasCurrentIssueIdIncomplete'] = false
            this.filters['hasPsADiagnosis'] = false
            this.filters['hasVerifiedDocuments'] = false
            this.filters['hasUnverifiedDocuments'] = false

        },
        hasStatus(patient) {
            return patient.status != null
        },
        percentageComplete(pIssue) {
            var result = pIssue.pc_complete
            return Math.round((result.valid / (result.valid + result.invalid + result.unstarted)) * 100)
        },
        formatChartData(pIssue) {
            var result = pIssue.pc_complete
            return [
                { val: result.valid },
                { val: result.invalid },
                { val: result.unstarted },
            ]
        },
        sortField(field) {
            var fields = {
                'id': 'patients.id',
                'user_id': 'patients.user_id',
                'country': 'patients.country_id',
                'code': 'patients.code',
                'name': 'users.first_name',
                'email': 'users.email',
                'consent': 'patients.isConsented',
                'issues': 'patients.currentIssueCompletion',
                'notifications': 'users.recentNotificationCount',
                'activity': 'users.latestActivity',
                'documents': 'patients.documentsSort',
            }
            return fields[field]
        },

        filtersApplied(keyList) {
            return Object.keys(this.filters)
                .filter(key => keyList.includes(key))
                .filter(key => this.filters[key] && (Array.isArray(this.filters[key]) ? this.filters[key].length : true))

                .filter(Boolean).length
        },
        issueSummaryPopoverBody(issueSummary, patient) {
            return [this.$t('admin.patients.issue_summary.popover', {
                issue: this.$t('issues.' + issueSummary.issue.name + '.title'),
                due_at: this.$t('admin.patients.issue_summary.due_at', { due_at: this.$d(new Date(issueSummary.due_at), 'short', 'en-GB') }),
            }),
            issueSummary.notifications_sent ? this.$t('admin.patients.issue_summary.notifications_sent', {
                notifications_sent: issueSummary.notifications_sent,
            }) : '',
            this.$t('admin.patients.issue_summary.completed', {
                completed: issueSummary.pc_complete.valid,
                total: issueSummary.pc_complete.valid + issueSummary.pc_complete.invalid + issueSummary.pc_complete.unstarted,
                percentage: this.percentageComplete(issueSummary),
            }),
            ].join('<br>')
        },
        issueSummaryPopoverTitle(issueSummary, patient) {
            return this.$t('admin.patients.issue_summary.issue', {
                issue: issueSummary.issue.name,
            })
        },
        toggleConnectedFilter(key, otherKey, pagination) {
            if (this.filters[key]) {
                this.filters[otherKey] = false
            }
            this.makeRequest({}, pagination)
        },
    },
    watch: {
        '$route.name'() {
            this.updateFilters()
            this.makeRequest({}, this.pagination)
        }
    }
}

</script>
