<template>
    <offcanvas placement="bottom" :show="!consentRequested" role="dialog" aria-labelledby="cookie-consent"
        aria-modal="true" style="--bs-offcanvas-height:10rem; width: 100vw;" :backdrop="'static'"
        :class="'bg-' + $root.theme + ' bg-gradient'">
        <template #header>
            <div class="offcanvas-header row align-items-center px-1 container m-auto">
                <column :sizes="{ xs: 12, md: 6, lg: 4 }">
                    <img :src="'/images/hpos_logo_short.svg'" alt="hpos.ndorms.ox.ac.uk" class="float-start"
                        style="width:80px;" />
                    <h5 id="cookie-consent" class="mx-4 offcanvas-title text-center"
                        v-t="'settings.cookie-consent.title'">
                    </h5>


                </column>
                <!-- <button type="button" class="col col-1 btn-close" @click="declineCookies"
                            aria-label="Close"></button> -->

                <column :sizes="{ xs: 12, md: 6, lg: 4 }" class="order-1 ">
                    <i18n path="settings.cookie-consent.message" class="my-1 mx-2">
                        <template #link>
                            <a href="/about/cookie-policy" v-t="'settings.cookie-consent.link'"></a>
                        </template>
                    </i18n>
                </column>
                <column :sizes="{ xs: 12, md: 5, lg: 4 }" class="order-2">
                    <btn-group justified class="mt-2">
                        <btn @click.native="acceptCookies">Accept</btn>
                        <btn @click.native="declineCookies" theme="secondary">Decline</btn>
                    </btn-group>
                </column>
            </div>
        </template>

    </offcanvas>
</template>

<script>
export default {
    name: 'CookieConsent',
    data() {
        return {
            consentRequested: false,
            consentGiven: false
        }
    },
    created() {
        var cc = JSON.parse(localStorage.getItem('cookie-consent'))
        this.consentRequested = cc !== null;
        if (cc.ttl > Date.now()) this.consentGiven = cc.value === 'true';
        else {
            localStorage.setItem('cookie-consent', null)
        }
    },
    methods: {
        acceptCookies() {

            localStorage.setItem('cookie-consent', JSON.stringify({
                value: true,
                ttl: Date.now() + 7776000 * 1000
            }));
            this.consentGiven = true;
            this.consentRequested = true;
            this.$emit('consentChanged', true);
        },
        declineCookies() {
            localStorage.setItem('cookie-consent', JSON.stringify({
                value: false,
                ttl: Date.now() + 7776000 * 1000
            }));
            this.consentGiven = false;
            this.consentRequested = true;
            this.$emit('consentChanged', false);
        },
    },
}
</script>

<style lang="scss" scoped></style>