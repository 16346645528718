<template>
  <div :class="['admin-dashboard', { 'sidebar-showing': sidebarMenuShown }]">
    <div :class="['outer-grid', { 'container-xxl': !fullscreen }]">
      <nav class="dashboard-page-list">
        <card :body="false">
          <list-group flush>
            <list-group-item v-for="page in dashboardPages" @click.native="changePage(page)" :key="page.title"
              :class="{ active: page.name == currentDashboardPage }">{{
                page.title }}</list-group-item>
          </list-group>
        </card>
      </nav>
      <!-- <scope :req="dashboard.stream.requests.patients" v-slot="{ req }">
        <div class="progress" role="progressbar" aria-label="Success example" :aria-valuenow="req.percentageComplete"
          aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar bg-success" :style="{ width: req.percentageComplete + '%' }">{{
            req.percentageComplete
          }}</div>
        </div>
        {{ dashboard.stream.patients.length }}
        {{ dashboard.stream.requests.patients }}
      </scope> -->
      <recruitment-dashboard v-if="currentDashboardPage == 'recruitment'"></recruitment-dashboard>
      <demographics-dashboard v-if="currentDashboardPage == 'demographics'"></demographics-dashboard>
      <participant-flow-dashboard v-if="currentDashboardPage == 'participant-flow'"></participant-flow-dashboard>
      <questionnaires-dashboard v-if="currentDashboardPage == 'questionnaires'"></questionnaires-dashboard>
    </div>
    <nav :class="['card sidebar-nav', { show: sidebarMenuShown }]">
      <div class="card-header d-flex">
        <h5 class="card-title flex-grow-1">
          <fa icon="filter"></fa>
          <span class="ms-2">
            {{ $t('admin.dashboard.filters.title') }}
          </span>
        </h5>
        <button type="button" class="btn-close p-0" aria-label="Close" @click="closeOCMenu"></button>
      </div>
      <div class="card-body">
        <dashboard-filters :other="{ countriesArr }"></dashboard-filters>
      </div>
    </nav>
    <div class="card toolbar">
      <!-- fullscreen btn -->
      <btn btn-class="icon">
        <fa icon="expand" @click="toggleFullscreen"></fa>
      </btn>
      <btn btn-class="icon" :true-value="true" v-model="sidebarMenuShown">
        <fa icon="filter"></fa>
      </btn>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import patientCount from '~/components/admin-dashboard/patient-count.vue'
import latestRecruitment from '~/components/admin-dashboard/latest-recruitment.vue'
import recruitmentOverTime from '~/components/admin-dashboard/recruitment-over-time.vue'
import patientExplorer from '~/components/admin-dashboard/patient-explorer.vue'
import dataExplorer from '~/components/admin-dashboard/data-explorer/data-explorer.vue'
import participantFlow from '~/components/admin-dashboard/participant-flow.vue'
import questionnaireCompletion from '~/components/admin-dashboard/questionnaire-completion.vue'
import countryMixin from '~/mixins/country';
import countryCard from '~/components/admin-dashboard/country-card.vue'
import dashboardFilters from '~/components/dashboard-filters.vue'
import recruitmentDashboard from '~/components/admin-dashboard/recruitment-dashboard.vue'
import demographicsDashboard from '~/components/admin-dashboard/demographics-dashboard.vue'
import participantFlowDashboard from '~/components/admin-dashboard/participant-flow-dashboard.vue'
import questionnairesDashboard from '~/components/admin-dashboard/questionnaires-dashboard.vue'
export default {
  name: "admin-dashboard",
  middleware: ['auth', 'team'],
  mixins: [countryMixin],
  components: {
    recruitmentDashboard,
    demographicsDashboard,
    participantFlowDashboard,
    questionnairesDashboard,
    dashboardFilters,
  },
  layout: 'fullscreen-grid',
  metaInfo() {
    return { title: this.$t('admin.dashboard.title') }
  },
  data() {
    return {
      sidebarMenuShown: false,
      fullscreen: true,
      currentDashboardPage: 'recruitment',
      dashboardPages: [
        { name: 'recruitment', title: this.$t('admin.dashboard.pages.recruitment') },
        { name: 'demographics', title: this.$t('admin.dashboard.pages.demographics') },
        { name: 'questionnaires', title: this.$t('admin.dashboard.pages.questionnaires') },
        { name: 'participant-flow', title: this.$t('admin.dashboard.pages.participant-flow') },
      ],
      cards: [
        { component: 'recruitmentOverTime', size: 'size-3x2' },
        // { component: 'participantFlow', size: 'size-3x2' },
        // { component: 'patientCount' },
        // { component: 'latestRecruitment', size: 'size-2x1' },
        // { component: 'patientExplorer', size: 'size-2x1' },
        // { component: 'dataExplorer', size: 'size-2x1' }
      ]
    }
  },
  provide() {
    return {
      dashboard: this.dashboard,
      loadedDashboardData: this.loadedDashboardData,
      loadingDashboardData: this.loadingDashboardData,
    }
  },
  created() {
    this.getDashboardData()
      .then(() => {
        this.fetchAsStream({
          id: 'patient.country',
          resource: 'Patient',
          key: 'patients',
          initial: true,
          params: { attrs: ['country_id,recruitment_date'] }
        }) // Fetch all patients
      })
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('resources/admin', ['dashboard', 'loadingDashboardData', 'loadedDashboardData',]),

    patients() {
      return this.dashboard.data.patients
    }
  },
  methods: {
    ...mapActions('resources/admin', ['getDashboardData', 'fetchAsStream']),

    toggleFullscreen() {
      this.fullscreen = !this.fullscreen
    },
    closeOCMenu() {
      this.sidebarMenuShown = false
    },
    changePage(e) {
      this.currentDashboardPage = e.name
    }
  }
}

</script>
