<template>
  <fieldset name="filters" class="p-1">
    <legend class="sr-only" v-t="'admin.patients.filter.title'"></legend>
    <nav-bar tabs @change="changeTab" :links="filterTabs" :current="currentTab">
      <template #nav-item-inner="{ link }">
        <span v-if="['xs', 'sm'].includes($mq)"> {{ link.short_title }} </span>
        <span v-else> {{ link.title }} </span>
      </template>
      <div class="ms-auto">
        <btn outline @click.native="clearAllFilters(); request({}, pagination)" :disabled="!filtersApplied(filterList)">
          <fa icon="times" class="me-1"></fa>
          <span v-t="'admin.patients.filters.clear'"></span>
        </btn>
      </div>
    </nav-bar>

    <tab-content :tabs="filterTabs" :current="currentTab" class="p-2 card border-top-0 rounded-0">
      <template #patients>
        <column :sizes="{ md: 12 }" class="d-flex flex-row flex-wrap">
          <form-group v-if="isAdmin" :di="{ name: 'patients.country_id', label: $t('admin.patients.columns.country') }"
            class="my-1 me-2">

            <dropdown justified btn-outline :items="filter_data.countries" v-model="filtersCopy['patients.country_id']"
              value-name="id" @input="request({}, pagination)" label-name="name" multiple btn-label="">
              <template #btn-inner="{ chosenLabel }">
                <div class="d-flex">
                  <template v-for="(c_id, i) in filtersCopy['patients.country_id']">
                    <scope :country="filter_data.countries.find((c) => c.id == c_id)" v-slot="{ country }"
                      :key="'scope' + c_id">
                      <svg class="flag-img rounded-left img-fluid me-1"
                        style="width:24px; box-shadow: 0 0 1px 1px #ddd;" :alt="country.name" :title="country.name">
                        <use :href="'/images/home/flags-sprite.svg#' + country.code.toLowerCase()">
                        </use>
                      </svg>
                    </scope>
                  </template>
                  <span>{{ chosenLabel }}</span>
                </div>
              </template>
              <template #list-item-prepend="{ item }">
                <svg class="flag-img rounded-left img-fluid ms-n1 me-1"
                  style="width:24px; box-shadow: 0 0 1px 1px #ddd;" :alt="item.name">
                  <use :href="'/images/home/flags-sprite.svg#' + item.code.toLowerCase()">
                  </use>
                </svg>
              </template>
            </dropdown>

          </form-group>
          <form-group :di="{ name: 'code', label: $t('admin.patients.columns.hpos_code') }" class="my-1 me-2">
            <input-group>
              <template #prepend>
                <div class="input-group-text">HPOS -</div>
              </template>
              <!-- mask should be 00-0000 -->
              <textbox type="text" v-model="filtersCopy['patients.code']" v-mask="{
                mask: '##-####',
                pattern: /[0-9]/
              }"></textbox>
              <template #append v-if="filtersCopy['patients.code']">
                <btn @click.native="request({}, pagination)" v-t="'admin.patients.search.title'" size="sm"></btn>
                <btn btn-class="secondary" @click.native="filtersCopy['patients.code'] = null; request({}, pagination)"
                  size="sm" v-t="'admin.patients.search.clear'"></btn>
              </template>
            </input-group>
          </form-group>
          <form-group :di="{ name: 'users.email', label: $t('admin.patients.columns.email') }" class="my-1 me-2">
            <input-group>
              <textbox type="email" required v-model="filtersCopy['users.email']"></textbox>
              <template #append v-if="filtersCopy['users.email']">
                <btn @click.native="request({}, pagination)" v-t="'admin.patients.search.title'"></btn>
                <btn btn-class="secondary" @click.native="filtersCopy['users.email'] = null; request({}, pagination)"
                  v-t="'admin.patients.search.clear'"></btn>
              </template>
            </input-group>
          </form-group>

          <form-group v-if="$route.name == 'admin.patients'"
            :di="{ name: 'hasUnverifiedDocuments', label: $t('admin.patients.columns.documents') }" class="my-1 me-2">
            <btn-group justified>
              <btn outline v-model="filtersCopy['hasVerifiedDocuments']" :true-value="true" :false-value="false"
                @click.native="toggleConnectedFilter('hasVerifiedDocuments', ['hasUnverifiedDocuments'], pagination)"
                v-t="'admin.patients.filters.hasVerifiedDocuments'">
              </btn>
              <btn outline v-model="filtersCopy['hasUnverifiedDocuments']" :true-value="true" :false-value="false"
                @click.native="toggleConnectedFilter('hasUnverifiedDocuments', ['hasVerifiedDocuments'], pagination)"
                v-t="'admin.patients.filters.hasUnverifiedDocuments'">
              </btn>
            </btn-group>
          </form-group>
          <!-- hasPsADiagnosis -->
          <form-group v-if="$route.name == 'admin.patients'"
            :di="{ name: 'hasPsADiagnosis', label: $t('admin.patients.columns.diagnosis') }" class="my-1 me-2">
            <btn outline v-model="filtersCopy['hasPsADiagnosis']" :true-value="true" :false-value="false"
              @click.native="request({}, pagination)" v-t="'admin.patients.filters.hasPsADiagnosis'" class="w-100">
            </btn>
          </form-group>
        </column>
      </template>
      <template #issues>
        <row>
          <column :sizes="{ md: 6 }" class="d-flex flex-column flex-wrap">
            <form-group v-if="$route.name == 'admin.patients'" :di="{ name: 'issues' }">
              <label for="" class="form-label me-4" v-text="$t('admin.patients.columns.issues')"></label>
              <div class="d-flex flex-column flex-grow-1">
                <btn-group justified class="mb-1">
                  <template v-for="n in filter_data.max_issues">
                    <btn outline v-model="filtersCopy[getIssueKey('IssueId')]" :true-value="n" :false-value="false"
                      @click.native="request({}, pagination)" :key="getIssueKey('IssueId') + n">
                      <span v-text="n"></span>
                    </btn>
                  </template>
                </btn-group>
                <checkbox :label="$t('admin.patients.filters.isCurrentIssue')" v-model="isCurrentIssue">
                </checkbox>
                <btn-group justified :vertical="['xs', 'sm', 'md', 'lg'].includes($mq)">
                  <btn theme="success" outline v-model="filtersCopy[getIssueKey('IssueIdComplete')]"
                    :true-value="filtersCopy[getIssueKey('IssueId')]" :false-value="false"
                    :disabled="!filtersCopy[getIssueKey('IssueId')]"
                    @click.native="toggleConnectedFilter(getIssueKey('IssueIdComplete'), [getIssueKey('IssueIdIncomplete'), getIssueKey('IssueIdUnstarted')], pagination)"
                    v-t="'admin.patients.filters.' + getIssueKey('IssueComplete')">
                  </btn>
                  <btn theme="warning" outline v-model="filtersCopy[getIssueKey('IssueIdIncomplete')]"
                    :true-value="filtersCopy[getIssueKey('IssueId')]" :false-value="false"
                    :disabled="!filtersCopy[getIssueKey('IssueId')]"
                    @click.native="toggleConnectedFilter(getIssueKey('IssueIdIncomplete'), [getIssueKey('IssueIdComplete'), getIssueKey('IssueIdUnstarted')], pagination)"
                    v-t="'admin.patients.filters.' + getIssueKey('IssueIncomplete')">
                  </btn>
                  <btn theme="secondary" outline v-model="filtersCopy[getIssueKey('IssueIdUnstarted')]"
                    :true-value="filtersCopy[getIssueKey('IssueId')]" :false-value="false"
                    :disabled="!filtersCopy[getIssueKey('IssueId')]"
                    @click.native="toggleConnectedFilter(getIssueKey('IssueIdUnstarted'), [getIssueKey('IssueIdComplete'), getIssueKey('IssueIdIncomplete')], pagination)"
                    v-t="'admin.patients.filters.' + getIssueKey('IssueUnstarted')">
                  </btn>
                </btn-group>

              </div>
            </form-group>
          </column>
          <column :sizes="{ md: 6 }" class="d-flex flex-column flex-wrap">
            <scope :issueId="filtersCopy[getIssueKey('IssueId')]"
              :model="filtersCopy['sentNNotificationsForIssueIdOnly']" v-slot="{ issueId, model }"
              v-if="notificationNames.length">
              Notifications
              <btn-group justified vertical class="my-2">
                <template v-for="(label, n) in notificationNames">
                  <btn outline v-model="notificationModelName" :true-value="label" :false-value="false"
                    :key="issueId + label">
                    <span v-text="label"></span>
                  </btn>
                </template>
              </btn-group>
              <btn-group justified v-if="notificationModelName">
                <template v-for="(label, n) in notificationStages">
                  <btn outline v-model="notificationStage" :true-value="'reminder' + n" :false-value="null"
                    :key="issueId + label">
                    <span v-text="label"></span>
                    <span v-if="!notificationCountOnly && n > 0">+</span>
                  </btn>
                </template>
              </btn-group>
              <checkbox
                v-if="!notSentNotifications && (filtersCopy.sentNNotificationsForIssueId || filtersCopy.sentNNotificationsForIssueIdOnly)"
                :label="$t('admin.patients.filters.onlySent')" v-model="notificationCountOnly">
              </checkbox>
            </scope>
          </column>
        </row>
      </template>
      <template #questionnaires>
        <column :sizes="{ md: 12 }" class="d-flex flex-column flex-wrap">
          <form-group :di="{ name: 'questionnaires', label: $t('admin.questionnaires.title') }">
            (COMING SOON)
            <!-- {{ scheduledQuestionnaires }} -->
          </form-group>
        </column>
      </template>
    </tab-content>
  </fieldset>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  name: "PatientFilters",

  props: {
    filters: {
      type: Object,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    request: {
      type: Function,
      required: true
    },
    other: {
      type: Object,
    },
  },
  data() {
    return {
      filterTabs: {
        patients: { title: this.$t('admin.patients.title'), key: 'patients' },
        issues: { title: this.$t('admin.patients.columns.issues'), key: 'issues' },
        questionnaires: { title: this.$t('admin.questionnaires.title'), key: 'scores' },
      },
      currentTab: 'patients',
      isCurrentIssue: false,
      notificationModelName: null,
      notificationStage: null,
      notSentNotifications: null,
      notificationCountOnly: false,
      filtersCopy: {
        'users.email': null,
        'patients.country_id': [],
        'patients.code': null,
        'consented': this.$route.name == 'admin.patients' ? true : false,
        'notConsented': this.$route.name == 'admin.patients.registration' ? true : false,
        'hasIssueId': null,
        'hasIssueIdComplete': false,
        'hasIssueIdIncomplete': false,
        'hasIssueIdUnstarted': false,
        'hasCurrentIssueId': null,
        'hasCurrentIssueIdComplete': false,
        'hasCurrentIssueIdIncomplete': false,
        'hasCurrentIssueIdUnstarted': false,
        'hasPsADiagnosis': false,
        'hasVerifiedDocuments': false,
        'hasUnverifiedDocuments': false,
        'sentNNotificationsForIssueId': false,
        'sentNNotificationsForIssueIdOnly': null,
        'notSentNNotificationsForIssueId': null,
      },
    }
  },
  mounted() {
    this.getPatientFilterData().then(() => {
      this.filtersCopy = this.filters
    })

  },
  computed: {
    ...mapGetters('auth', ['isAdmin']),
    ...mapState('resources/patient', ['filter_data']),
    notificationStages() {
      var issueId = this.filtersCopy[this.getIssueKey('IssueId')]
      if (!issueId) return []
      return issueId == 1 || this.notificationModelName !== 'IssueDue' ? ['none', 'reminder1', 'reminder2', 'reminder3'] : ['none', 'invite', 'reminder1', 'reminder2', 'reminder3']
    },
    notificationNames() {
      var issueId = this.filtersCopy[this.getIssueKey('IssueId')]
      if (!issueId) return []
      return issueId == 1 ? ['IssueIncomplete'] : ['IssueDue', 'IssueIncomplete']
    },
    notificationFilterName() {
      return this.notSentNotifications ? 'notSentNNotificationsForIssueId' : this.notificationCountOnly ? 'sentNNotificationsForIssueIdOnly' : 'sentNNotificationsForIssueId'
    },
    scheduledQuestionnaires() {
      return this.filter_data['patientIssue.questionnaires']
    },
    filterList() {
      return Object.keys(this.filtersCopy).filter(key => !['consented', 'notConsented'].includes(key))
    },
  },
  methods: {
    ...mapActions('resources/patient', ['getPatientFilterData']),
    getIssueKey(suffix) {
      return 'has' + (this.isCurrentIssue ? 'Current' : '') + suffix
    },
    filtersApplied(keyList) {
      return Object.keys(this.filters)
        .filter(key => keyList.includes(key))
        .filter(key => this.filtersCopy[key] && (Array.isArray(this.filtersCopy[key]) ? this.filtersCopy[key].length : true))

        .filter(Boolean).length
    },
    resetIssueFilters() {
      if (this.isCurrentIssue) {
        this.filtersCopy['hasIssueId'] = false
      } else {
        this.filtersCopy['hasCurrentIssueId'] = false
      }
      this.filtersCopy['hasCurrentIssueIdComplete'] = false
      this.filtersCopy['hasCurrentIssueIdIncomplete'] = false
      this.filtersCopy['hasCurrentIssueIdUnstarted'] = false
      this.filtersCopy['hasIssueIdComplete'] = false
      this.filtersCopy['hasIssueIdIncomplete'] = false
      this.filtersCopy['hasIssueIdUnstarted'] = false
      this.filtersCopy['sentNNotificationsForIssueId'] = false
      this.filtersCopy['sentNNotificationsForIssueIdOnly'] = false
      this.filtersCopy['notSentNNotificationsForIssueId'] = false
      this.notificationModelName = false
      this.notificationStage = null
    },
    updateFilters() {
      this.filtersCopy['consented'] = this.$route.name == 'admin.patients' ? true : false
      this.filtersCopy['notConsented'] = this.$route.name == 'admin.patients.registration' ? true : false
      this.filtersCopy['hasIssueId'] = false
      this.filtersCopy['hasCurrentIssueId'] = false
      this.filtersCopy['hasCurrentIssueIdComplete'] = false
      this.filtersCopy['hasCurrentIssueIdIncomplete'] = false
      this.filtersCopy['hasCurrentUnstartedIncomplete'] = false
      this.filtersCopy['hasPsADiagnosis'] = false
      this.filtersCopy['hasVerifiedDocuments'] = false
      this.filtersCopy['hasUnverifiedDocuments'] = false
      this.filtersCopy['sentNNotificationsForIssueId'] = false
      this.filtersCopy['sentNNotificationsForIssueIdOnly'] = false
      this.filtersCopy['notSentNNotificationsForIssueId'] = false

    },
    toggleConnectedFilter(key, otherKeys, pagination) {
      if (this.filtersCopy[key]) {
        for (const otherKey of otherKeys) {
          this.filtersCopy[otherKey] = false
        }
      }
      this.request({}, pagination)
    },
    setNotificationCount() {
      if (this.notificationStage && this.notificationStage.startsWith('reminder')) {
        var issueId = this.filtersCopy[this.getIssueKey('IssueId')]
        var reminderN = Math.round(this.notificationStage.split('reminder')[1]);
        if (reminderN == 0) {
          this.notSentNotifications = true
          this.filtersCopy.sentNNotificationsForIssueIdOnly = false
          this.filtersCopy.sentNNotificationsForIssueId = false
        }
        else {
          this.notSentNotifications = false
          reminderN = reminderN - 1
          this.filtersCopy.notSentNNotificationsForIssueId = false
        }
        var fullString = issueId + ',' + 'App\\Notifications\\' + this.notificationModelName + ',' + reminderN
        this.filtersCopy[this.notificationFilterName] = fullString
        if (this.notificationCountOnly) {
          this.filtersCopy['sentNNotificationsForIssueId'] = false
        } else {
          this.filtersCopy['sentNNotificationsForIssueIdOnly'] = false
        }
        this.request({}, this.pagination)
      } else {
        this.filtersCopy['sentNNotificationsForIssueIdOnly'] = false
        this.filtersCopy['sentNNotificationsForIssueId'] = false
        this.filtersCopy['notSentNNotificationsForIssueId'] = false
        this.request({}, this.pagination)
      }

    },
    changeTab(tab) {
      this.currentTab = tab
    },
    clearAllFilters() {
      this.filtersCopy = {
        'users.email': null,
        'patients.country_id': [],
        'patients.code': null,
        'consented': this.$route.name == 'admin.patients' ? true : false,
        'notConsented': this.$route.name == 'admin.patients.registration' ? true : false,
        'hasIssueId': null,
        'hasIssueIdComplete': false,
        'hasIssueIdIncomplete': false,
        'hasIssueIdUnstarted': false,
        'hasCurrentIssueId': null,
        'hasCurrentIssueIdComplete': false,
        'hasCurrentIssueIdIncomplete': false,
        'hasCurrentIssueIdUnstarted': false,
        'hasPsADiagnosis': false,
        'hasVerifiedDocuments': false,
        'hasUnverifiedDocuments': false,
        'sentNNotificationsForIssueId': false,
        'sentNNotificationsForIssueIdOnly': null,
        'notSentNNotificationsForIssueId': null,
      }
    }
  },
  watch: {
    'filtersCopy.hasIssueId'() {
      this.resetIssueFilters()
    },
    'filtersCopy.hasCurrentIssueId'() {
      this.resetIssueFilters()
    },
    isCurrentIssue() {
      this.resetIssueFilters()
    },
    'filtersCopy.hasIssueId'() {
      this.resetIssueFilters()
    },
    'filtersCopy.hasCurrentIssueId'() {
      this.resetIssueFilters()
    },
    notificationModelName() {
      this.setNotificationCount()
    },
    notificationStage() {
      this.setNotificationCount()
    },
    notificationCountOnly() {

      this.setNotificationCount()

    },
    filtersCopy: {
      handler() {
        this.$emit('update-filters', this.filtersCopy)
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped></style>