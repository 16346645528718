<template>
  <row class="g-2">
    <column :sizes="{ lg: 4 }">
      <alert type="info">
        <row>
          <column :sizes="{ md: 1, lg: 12 }" class="d-flex mb-2">
            <fa icon="thumbs-up" size="3x" :class="['m-auto']" />
          </column>
          <column :sizes="{ md: 11, lg: 12 }">
            <p class="lead">{{ $t(lp + '.first_alert') }}</p>
            <span>
              {{ $t("questionnaires.register.contact_us") }}<a :href="'mailto:' + hposEmail" class="icon-link">
                <fa icon="envelope" /> {{ hposEmail }}
              </a>
            </span>
          </column>
        </row>
      </alert>
      <alert type="info">
        <row>
          <column :sizes="{ md: 1, lg: 12 }" class="d-flex mb-2">
            <fa icon="clipboard-list" size="3x" :class="['m-auto']" />
          </column>
          <column :sizes="{ md: 11, lg: 12 }">
            <p class="lead">{{ $t(lp + '.second_alert') }}</p>
          </column>

        </row>
      </alert>
    </column>
    <column :sizes="{ xs: 12, lg: 8 }">
      <card head v-if="mustVerifyEmail" :title="$t('login.register')">
        <div class="alert alert-success" role="alert">
          {{ $t('verify_email_address') }}
        </div>
      </card>
      <form v-else @submit.prevent>
        <card head foot :title="$t('questionnaires.register.eligibility')" v-if="dataitems" class="mb-4"
          :class="{ 'was-validated': firstStageValidate }">
          <row form>
            <form-group :error="eligible ? errors.has_psoriasis : records.errors.has_psoriasis"
              :di="dataitems.has_psoriasis" :cols="[{ md: 6 }, { md: 6 }]" class="mb-4">
              <btn-group outline justified :di="dataitems.has_psoriasis" v-model="records.has_psoriasis" />
            </form-group>
            <form-group :error="eligible ? errors.has_psa : records.errors.has_psa" :di="dataitems.has_psa"
              :cols="[{ md: 6 }, { md: 6 }]" class="mb-4">
              <btn-group outline justified :di="dataitems.has_psa" v-model="records.has_psa" />
            </form-group>
            <form-group :error="eligible ? errors.dob : records.errors.dob" :di="dataitems.dob"
              :cols="[{ md: 6 }, { md: 6 }]" class="mb-4">
              <datepicker :di="dataitems.dob" v-model="records.dob" />
            </form-group>
          </row>
          <transition name="fade">
            <alert v-if="ineligible">
              {{ $t('questionnaires.register.ineligible') }}
            </alert>
          </transition>
          <template #footer-inner>
            <transition name="fade" mode="out-in">
              <router-link v-if="ineligible" class="btn btn-primary w-100" block :outline="false" to="/">
                {{ $t('questionnaires.register.ineligible_return') }}
              </router-link>
              <btn v-else-if="!eligible" block :outline="false" @click.native="tryContinue"
                @keydown.Enter="tryContinue">
                {{ $t('questionnaires.register.continue') }}
              </btn>
              <template v-else-if="eligible">
                <fa icon="thumbs-up" size="3x" :class="['text-success m-auto']" />
              </template>
            </transition>
          </template>
        </card>
        <transition name="fade">
          <card :body="false" head foot :title="$t('questionnaires.register.heading')" v-if="dataitems && eligible"
            :class="{ 'was-validated': (questionnaire && questionnaire.wasValidated) }">

            <div class="card-body">
              <row form>
                <form-group :error="errors.first_name" :di="dataitems.first_name" :sizes="{ md: 6 }" class="mb-4">
                  <textbox :di="dataitems.first_name" v-model="records.first_name" />
                </form-group>
                <form-group :error="errors.last_name" :di="dataitems.last_name" :sizes="{ md: 6 }" class="mb-4">
                  <textbox :di="dataitems.last_name" v-model="records.last_name" />
                </form-group>
              </row>
              <row form>

                <form-group :error="errors.gender" :di="dataitems.gender" :sizes="{ md: 6 }" class="mb-4">
                  <btn-group outline justified :di="dataitems.gender" v-model="records.gender" />
                </form-group>
                <form-group :error="errors.country" :di="dataitems.country" :sizes="{ md: 6 }" class="mb-4">
                  <dropdown :items="dataitems.country.lookup.items" v-model="records.country" btn-outline justified />
                </form-group>
              </row>
              <row form>
                <form-group :error="errors.locale" :di="dataitems.locale" :cols="[{ md: 5 }, { md: 7 }]" class="mb-4">
                  <LocaleDropdown justified should-emit v-model="records.locale"></LocaleDropdown>
                </form-group>
              </row>
              <row form>
                <form-group :error="errors.found_hpos_lookup" :di="dataitems.found_hpos_lookup" :sizes="{ md: 12 }"
                  class="mb-4" :cols="[{ md: 5 }, { md: 7 }]">
                  <dropdown :items="dataitems.found_hpos_lookup.lookup.items" v-model="records.found_hpos_lookup"
                    btn-outline justified :btn-label="$t('components.dropdown.choose')" />
                </form-group>
                <form-group v-if="records.found_hpos_lookup == 9" :sizes="{ md: 12 }" :error="errors.found_hpos"
                  :di="dataitems.found_hpos" class="mb-4" :cols="[{ md: 5 }, { md: 7 }]">
                  <textbox :di="dataitems.found_hpos" v-model="records.found_hpos" />
                </form-group>
              </row>

              <fieldset class="mb-4 row d-flex align-items-center">
                <column :sizes="{ sm: 6 }">
                  <checkbox v-if="records.country" :di="dataitems.read_pis" v-model="records.read_pis"
                    :error="errors.read_pis" class="mb-1"
                    :label="$t('questionnaires.register.dataitems.read_pis.label', { country: selectedCountry.code })">
                  </checkbox>
                </column>
                <column :sizes="{ sm: 6 }">
                  <a v-if="records.country" :href="selectedCountry.pis_path" target="_blank"
                    class="icon-link d-flex flex-row p-2">
                    <fa icon="book-open-reader" class="my-1 me-1" />
                    <span>
                      {{ $t('questionnaires.register.dataitems.read_pis.link_text', {
                        country: selectedCountry.code,
                        link: $t('questionnaires.register.dataitems.read_pis.link_title')
                      }) }}
                    </span>
                  </a>
                </column>
              </fieldset>
            </div>
            <div class="card-body border-top pt-4">

              <!-- Email -->
              <form-group :error="errors.email || serverErrors.email || serverErrors.email_hash" :di="dataitems.email"
                :cols="[{ md: 5 }, { md: 7 }]" class="mb-4">
                <textbox :di="dataitems.email" autocomplete="email" v-model="records.email" />
              </form-group>
              <form-group
                :error="errors.email_confirmation || serverErrors.email_confirmation || serverErrors.email_confirmation"
                :di="dataitems.email_confirmation" :cols="[{ md: 5 }, { md: 7 }]" class="mb-4">
                <textbox :di="dataitems.email_confirmation" autocomplete="email" v-model="records.email_confirmation" />
              </form-group>
              <form-group :error="errors.password" :di="dataitems.password"
                :helptext="dataitems.password.trans('helptext')" :cols="[{ md: 5 }, { md: 7 }]" class="mb-4">
                <textbox type="password" autocomplete="new-password" :di="dataitems.password"
                  v-model="records.password" />
              </form-group>
              <form-group :error="errors.password_confirmation" :di="dataitems.password_confirmation"
                :cols="[{ md: 5 }, { md: 7 }]" class="mb-4">
                <textbox type="password" autocomplete="new-password" :di="dataitems.password_confirmation"
                  v-model="records.password_confirmation" />
              </form-group>
            </div>
            <template #footer-inner>
              <router-link v-if="ineligible" class="btn btn-primary w-100" block :outline="false" to="/">
                {{ $t('questionnaires.register.ineligible_return') }}
              </router-link>
              <btn v-else-if="!eligible" block :outline="false" @click.native="tryContinue"
                @keydown.Enter="tryContinue">
                {{ $t('questionnaires.register.continue') }}
              </btn>
              <btn v-else-if="eligible" block :outline="false" @click.native="register" @keydown.Enter="register"
                :class="[{ 'btn-loading': submitting }]">
                {{ $t('login.register') }}
              </btn>
            </template>
          </card>
        </transition>
      </form>
    </column>
  </row>
</template>
<script>
import axios from "axios"
import questionnaire from "~/mixins/questionnaire.js"
import LocaleDropdown from '~/components/LocaleDropdown.vue'

import { mapActions, mapState, mapGetters } from 'vuex';
export default {
  name: 'register',
  components: {
    LocaleDropdown
  },
  // added 'is-test-user' middleware for temporary blocking of the register page (unless url is /register?is-test-user=true)
  middleware: ['guest'],
  mixins: [questionnaire],
  metaInfo() {
    return { title: this.$t('login.register') }
  },
  destroyed() {
    this.reset = true
  },
  data() {
    return {
      hposEmail: this.$t('public.home.study_email'),
      reset: false,
      serverErrors: {},
      submitting: false,
      mustVerifyEmail: false,
      firstStageValidate: undefined,
    }
  },
  methods: {
    // ...mapActions('questionnaire', ['getQuestionnaireData']),
    dobAsAge(dateStr) {
      if (dateStr) {
        var birthday = new Date(dateStr);
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return 'Age: ' + Math.abs(ageDate.getUTCFullYear() - 1970);
      }
    },
    hasError(name) {
      return (this.errors[name] || this.form.errors.has(name))
    },
    getError(name) {
      if (this.hasError(name)) {
        if (this.form.errors.has(name)) return [this.form.errors.get(name)]
        else if (this.errors[name]) return this.errors[name]
      } else {
        return false
      }
    },
    errorsAreIneligible(errors) {
    },
    tryContinue() {
      if (this.ineligible) {
      } else if (this.eligible) {
        this.firstStageValidate = false;
      }
      else {
        if (this.firstStageValidate) {

        } else {
          this.firstStageValidate = true
          this.records.validate({});
          this.records.validationWatch(this, "records", {})
        }
      }
    },
    register() {
      this.validate_questionnaire()
      if (Object.keys(this.errors).length == 0) {
        // Register the user.
        this.submitting = true
        axios.post('/register', this.records)
          .then((resp) => {
            this.submitting = false
            // Log in the user.
            axios.post('login', this.records)
              .then(async (resp) => {
                await this.$store.dispatch('auth/saveToken', { token: resp.data.token })
                await this.$store.dispatch('auth/fetchUser')

                this.$router.push({ name: 'consent' });
                this.$gtag.event({
                  event_category: 'auth',
                  event_action: 'register',
                  event_label: 'User registered',
                  user_id: this.user.id
                })

              }).catch((error) => {
                // console.log('err', error.response)
              })
          })
          .catch((error) => {
            this.serverErrors = error.response.data.errors
          })
      }
    },
  },
  computed: {
    eligibilityErrors() {
      return this.records.validate({ rule_as_message: true, dont_set_errors: true });
    },
    ineligible() {
      var errors = this.eligibilityErrors;
      return (this.firstStageValidate !== undefined)
        && ((errors.has_psoriasis && errors.has_psoriasis.includes("in:1")) ||
          (errors.has_psa && errors.has_psa.includes("in:2")) ||
          (errors.dob && errors.dob.includes("above18")))
    },
    selectedCountry() {
      if (this.records.country && this.dataitems.country && this.dataitems.country.lookup)
        return this.dataitems.country.lookup.lookUp(this.records.country);
      return {}
    },
    eligible() {
      var errors = this.eligibilityErrors;
      return (this.firstStageValidate !== undefined) && (!errors.has_psoriasis && !errors.has_psa && !errors.dob)
    }
  },
  watch: {
    'records.country': {
      handler(newVal, oldVal) {
        if (this.records) {
          if (oldVal != undefined) {
            this.records.read_pis = null;
          }
        }
      }
    },
    records: {
      handler() {
        if (!this.reset && this.questionnaire && this.questionnaire.wasValidated) {
          this.validate_questionnaire()
        }
      },
      deep: true
    }
  },
}
</script>