<template>
  <dropdown v-if="Object.keys(locales).length > 1" btn-outline btn-class="secondary" :items="localesArray"
    :value="locale" @input="(key) => { setLocale(key) }">
    <template #btn-inner="{ item, toggleDropdown, chosenLabel }">
      <svg class="flag-img rounded-left img-fluid ms-n1 me-1" style="width:24px;"
        :alt="$t('settings.locales.' + $i18n.locale + '.flag').toLowerCase()">
        <use :href="'/images/home/flags-sprite.svg#' + $t('settings.locales.' + $i18n.locale + '.flag').toLowerCase()">
        </use>
      </svg>

      <label>{{ chosenLabel }}</label>
    </template>
    <template #list-item-prepend="{ item }">
      <svg class="flag-img rounded-left img-fluid ms-n1 me-1" style="width:24px; box-shadow: 0 0 1px 1px #ddd;"
        :alt="item.name">
        <use :href="'/images/home/flags-sprite.svg#' + item.flag.toLowerCase()">
        </use>
      </svg>

    </template>
  </dropdown>
</template>
<script>
import { mapGetters } from 'vuex'
import { loadLanguageAsync } from '~/plugins/i18n'

export default {
  props: {
    shouldEmit: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: 'en'
    },

  },
  computed: {
    ...mapGetters('lang', ['locales', 'locale']),
    localesArray() {
      return Object.keys(this.locales).map((key) => {
        return { value: key, label: this.$t('settings.locales.' + key + '.name'), flag: this.$t('settings.locales.' + key + '.flag') }
      })
    }
  },
  methods: {
    setLocale(locale) {
      if (this.$i18n.locale !== locale) {
        Promise.all([loadLanguageAsync(locale)])
          .then(() => {
            this.$store.dispatch('lang/setLocale', { locale })
            if (this.shouldEmit) {
              this.$emit('input', locale)
            }
          })
      }
    }
  }
}

</script>
